import React from "react";
import WorkmenCommunicationForm from "./WorkmenCommunicationForm";
import { Paper, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { IWorkmenCommunication } from "../WorkmenCommunicationType";

const AddWorkMenCommunication = () => {
  const loc = useLocation().state;
  const data = loc as IWorkmenCommunication;
  const title = data
    ? "Edit Workmen Communication"
    : "Add  WorkMen Communication";
  return (
    <div className="bg-blue-200 md:p-7 p-2 overflow-hidden h-[100%]">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          NON MOTOR
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <Link
                to="/non-motor/workmenCommunication"
                className="text-addButton font-bold text-sm"
              >
                Workmen Communication /
              </Link>

              <span className="text-grey-600 text-sm"> {title}</span>
            </div>
          </div>
        </Typography>
        {data ? (
          <WorkmenCommunicationForm propsData={data} />
        ) : (
          <WorkmenCommunicationForm />
        )}
      </Paper>
    </div>
  );
};

export default AddWorkMenCommunication;
