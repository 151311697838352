import { IEditBurglary } from "../IBurglary";
import EditBurglaryAPI from "./EditBurglaryAPI";

const EditBurglaryService = async ({ id, burglary }: IEditBurglary): Promise<any> => {
    try {
        const res = await EditBurglaryAPI({
            id,
            burglary
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default EditBurglaryService;
