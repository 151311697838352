//create or assign task:
export const  createTaskEp = ()=>{
return("/api/non-motor/task")
}

export const getAllTaskEp = ()=>{
    return("/api/non-motor/task")
}
export const getTaskByTaskIdEp = (taskId:string)=>{
    return(`/api/non-motor/task/${taskId}`)
}
export const getTaskByEmployeeIdEp = (eId:string)=>{
    return(`/api/non-motor/task/employee-id/${eId}`)
}
export const getTaskByAreaIdEp = (areaId:string)=>{
    return(`/api/non-motor/task/area-id/${areaId}`)
}
export const getTaskByInvestigationIdEp = (investigationId:string)=>{
    return(`/api/non-motor/task/investigation-id/${investigationId}`)
}
export const getTaskByStatusEp = ()=>{
    return(`/api/non-motor/task/status/assigned`)
}

export const getTaskByRmIdEp= (rmId:string)=>{
    return(`/api/non-motor/task/${rmId}`)
}

export const editTaskEp= (taskId:string)=>{
    return(`/api/non-motor/task/${taskId}`)
}
export const deleteTaskEp= (taskId:string)=>{
    return(`/api/non-motor/task/${taskId}`)
}

