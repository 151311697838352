export const getLiabilityEp = () => {
    return ("/api/non-motor/liability-lead")
}
export const getLiabilityByIdEp = (liabilityId: string) => {
    return (`/api/non-motor/liability-lead/${liabilityId}`)
}
export const editLiabilityEp = (liabilityId: string) => {
    return (`/api/non-motor/liability-lead/${liabilityId}`)
}
export const deleteLiabilityEp = (liabilityId: string) => {
    return (`/api/non-motor/liability-lead/${liabilityId}`)
}
export const addLiabilityEp = () => {
    return ("/api/non-motor/liability-lead")
}