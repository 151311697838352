import { Paper, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AddLiabilityForm from "./AddLiabilityForm";
import { ILiability } from "../../../../api/Liability/ILiability";

const AddLiability = () => {
  const loc = useLocation();
  const data = loc.state as ILiability;
  const title = data ? "Edit Fire" : "Add Fire";
  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          NON MOTOR
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <Link
                to="/non-motor/liability"
                className="text-addButton font-bold text-sm"
              >
                Fire /
              </Link>

              <span className="text-grey-600 text-sm"> {title}</span>
            </div>
          </div>
        </Typography>
        {data ? <AddLiabilityForm propsData={data} /> : <AddLiabilityForm />}
      </Paper>
    </div>
  );
};

export default AddLiability;
