import React, { FC, useState } from "react";
import { MenuItem, SidebarProps, SubMenuItem } from "./SideBarMenusList";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import useLogoClickHandler from "../../utils/useLogoClickHandler";
const DynamicSideBar: FC<SidebarProps> = ({
  isSidebarOpen,
  setSidebarOpen,
  menuList,
}) => {
  const navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);
  const [openSubMenus, setOpenSubMenus] = useState<string[]>([]);
  const handleMenuItemClick = (item: MenuItem | SubMenuItem) => {
    if (item.link) {
      navigate(item.link);
    } else {
      setActiveMenuItem(item.id === activeMenuItem ? null : item.id);
    }
  };
  const toggleSubMenu = (parentId: string) => {
    if (openSubMenus.includes(parentId)) {
      setOpenSubMenus(openSubMenus.filter((id) => id !== parentId));
    } else {
      setOpenSubMenus([...openSubMenus, parentId]);
    }
  };

  const renderMenuItems = (items: MenuItem[] | SubMenuItem[]) => {
    return items.map((item) => (
      <li
        key={item.id}
        className="relative max-h-100 scroll-hidden"
      >
        <div
          className={`flex items-center px-2 py-1 text-[15px] cursor-pointer rounded-lg sticky top-0 z-10 bg-white ${
            activeMenuItem === item.id
              ? "bg-safekaroDarkBlue text-white"
              : "text-black hover:bg-safekaroDarkBlue hover:text-white"
          }`}
          onClick={() => {
            if (item.subMenu) {
              toggleSubMenu(item.id as string);
            } else {
              handleMenuItemClick(item);
            }
          }}
        >
          {item.svgIcon && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="none"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d={item.svgIcon}
              />
            </svg>
          )}
        <span className="text-sm">{item.label}</span>
          
          {item.subMenu && (
            <i
              className={`fas fa-chevron-${
                openSubMenus.includes(item.id as string) ? "up" : "down"
              } text-sm text-gray-400 ml-auto`}
            />
          )}
        </div>
        {item.subMenu && openSubMenus.includes(item.id as string) && (
          <ul className="ml-4 mt-2 space-y-1 px-1 border-l border-gray-200 pl-2 scroll-hidden max-h-60">
            {renderMenuItems(item.subMenu)}
          </ul>
        )}
      </li>
    ));
  };
  

  const handleLogoClick = useLogoClickHandler();
  return (
    <div
      className={`${
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0 sticky top-0 z-20 md:flex flex-col w-60 bg-white h-screen shadow-lg border-r-2 border-[#FEF9F3] transition-transform delay-150 duration-200`}
    >
      <div className="flex items-center justify-center h-16 bg-white">
        <img
          src={logo}
          alt="Logo"
          className="w-32 cursor-pointer"
          onClick={handleLogoClick}
        />
      </div>
      <div className="md:hidden flex w-full justify-end">
        <button onClick={() => setSidebarOpen((prev) => !prev)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="flex-1 py-7 overflow-y-auto scroll-hidden">
        <ul className="space-y-2 ml-1 ">{renderMenuItems(menuList||[])}</ul>
      </div>
    </div>
  );
};

export default DynamicSideBar;
