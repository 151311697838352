import React from "react";
import { ITmenuItems, MenuItem, SidebarProps } from "./SideBarMenusList";
import DynamicSideBar from "./DynamicSideBar";

const menuItems: MenuItem[] = ITmenuItems;
const ITSidebar: React.FC<SidebarProps> = ({isSidebarOpen,setSidebarOpen}) => {
  return (
    <DynamicSideBar
      isSidebarOpen={isSidebarOpen}
      setSidebarOpen={setSidebarOpen}
      menuList={menuItems}
    />
  );
};

export default ITSidebar;
