import React from "react";
import { MenuItem, PartnerMenuItems, SidebarProps } from "./SideBarMenusList";
import DynamicSideBar from "./DynamicSideBar";
const menuItems: MenuItem[] = PartnerMenuItems;
const PartnerSidebar: React.FC<SidebarProps> = ({
  isSidebarOpen,
  setSidebarOpen,
}) => {
  return (
    <DynamicSideBar
      isSidebarOpen={isSidebarOpen}
      setSidebarOpen={setSidebarOpen}
      menuList={menuItems}
    />
  );
};
export default PartnerSidebar;
