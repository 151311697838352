import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editWorkmenCommunicationEp as endpoint } from "../apiEndpoints";
import { IEditWorkmenCommunication } from "../IWorkmenCommunication";

const EditWorkmenCommunicationAPI = async ({
    id, workmenCommunication
}: IEditWorkmenCommunication) => {
    const url = endpoint(id)
    const options: FetchOptions = {
        method: "PUT",
        body: workmenCommunication,
    }

    return fetchInterceptor(url, options)


};

export default EditWorkmenCommunicationAPI;