import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editLiabilityEp as endpoint } from "../apiEndpoints";
import { IEditLiability } from "../ILiability";

const EditLiabilityAPI = async ({
    id, liability
}: IEditLiability) => {
    const url = endpoint(id)
    const options: FetchOptions = {
        method: "PUT",
        body: liability,
    }

    return fetchInterceptor(url, options)


};

export default EditLiabilityAPI;