import { lazy } from "react";

const AddAccounts = lazy(() => import("../Account/AddAcoounts/AddAccounts"));
const Accounts = lazy(() => import("../Account/Accounts/Accounts"));
const CompareBrokerPayment = lazy(() => import("../Account/CompareBrokerPayment/compareBrokerPayment"));
const CreditDebits = lazy(() => import("../Account/CreditDebit/CreditDebits/CreditDebits"));
const AddCreditDebits = lazy(() => import("../Account/CreditDebit/AddCreditDebit/AddCreditDebit"));
const UpdatePayment = lazy(() => import("../Account/UpdatePayments/ManageBrokerPayment/ManageBrokerPayment"));
const ManagePartnerPayment = lazy(() => import("../Account/UpdatePayments/ManagePartnerPayment/ManagePartnerPayment"));
const ComparePartnerPayment = lazy(() => import("../Account/ComparePartnerPayment/comparePartnerPayment"));
const ViewAccountCreditDebitsDetails = lazy(() => import("../Account/Accounts/ViewAccountDetails/ViewAccountCreditDebitsDetails"));
const ViewCreditDebitByBrokerCard = lazy(() => import("../Account/CreditDebit/ViewCreditDebitByBroker/ViewCreditDebitByBrokerCard"));
const ViewCreditDebitByPartnerCard = lazy(() => import("../Account/CreditDebit/ViewCreditDebitByPartner/ViewCreditDebitByPartnerCard"));
const TdsPayInManage = lazy(() => import("../Account/TDS/PayInTDS/TdsPayInManage"));
const TdsPayOutManage = lazy(() => import("../Account/TDS/PayOutTDS/TdsPayOutManage"));
const BrokerCredit = lazy(() => import("../Account/UpdatePayments/BrokerCredit/BrokerCredit"));
const PartnerDebit = lazy(() => import("../Account/UpdatePayments/PartnerDebit/PartnerDebit"));
const TeamAccounts = lazy(() => import("../Admin/Team/Teams/Accounts"));
const ExcelPayIn = lazy(() => import("../Account/ExcelPayIn&Payout/ExcelPayIn"));
const ExcelPayout = lazy(() => import("../Account/ExcelPayIn&Payout/ExcelPayout"));

export {
    AddAccounts,
    Accounts,
    CompareBrokerPayment,
    CreditDebits,
    AddCreditDebits,
    UpdatePayment,
    ManagePartnerPayment,
    ComparePartnerPayment,
    ViewAccountCreditDebitsDetails,
    ViewCreditDebitByBrokerCard,
    ViewCreditDebitByPartnerCard,
    TdsPayInManage,
    TdsPayOutManage,
    BrokerCredit,
    PartnerDebit,
    TeamAccounts,
    ExcelPayIn,
    ExcelPayout,
};
