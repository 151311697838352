import { IGetWorkmenCommunication } from "../IWorkmenCommunication";
import GetWorkmenCommunicationAPI from "./GetWorkmenCommunicationAPI";
const GetWorkmenCommunicationService = async ({ header }: IGetWorkmenCommunication): Promise<any> => {
    try {
        const res = await GetWorkmenCommunicationAPI({ header });
        return res;
    } catch (error) {
        throw error
    }
};

export default GetWorkmenCommunicationService;