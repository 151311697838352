export const getOccupancyEp = ()=>{
    return('/api/occupancy')
}
export const postOccupancyEp = ()=>{
    return('/api/occupancy')
}
export const editOccupancyEp = (id:string)=>{
    return(`/api/occupancy/${id}`)
}
export const getByIdOccupancyEp = (Id:string)=>{
    return(`/api/occupancy/${Id}`)
}