import { useEffect, useState } from "react";
import GetOccupancyService from "../../api/Occupancy/GetOccupancy/GetOccupancyService";
import { header } from "../../context/constant";
import { IOccupancy } from "../../components/Admin/Occupancy/OccupancyType";
export const useGetOccupancy = () => {
    const [data, setData] = useState<IOccupancy[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await GetOccupancyService({ header });
            setData(result.data);
        } catch (error: any) {
            const err = await error;
            setError(err.message || "Something went wrong");
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    return { data, loading, error };
};