import React from "react";
import { BookingMenuItems, MenuItem, SidebarProps } from "./SideBarMenusList";
import DynamicSideBar from "./DynamicSideBar";
const menuItems: MenuItem[] = BookingMenuItems;
const BookingSidebar: React.FC<SidebarProps> = ({
  isSidebarOpen,
  setSidebarOpen,
}) => {
  return (
    <DynamicSideBar
      isSidebarOpen={isSidebarOpen}
      setSidebarOpen={setSidebarOpen}
      menuList={menuItems}
    />
  );
};
export default BookingSidebar;
