import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { addMarineEp as endpoint } from "../apiEndpoints";
import { IAddMarine } from "../IMarine";

const AddMarineAPI = async ({
    marine
}: IAddMarine) => {

    const url = endpoint()
    const options: FetchOptions = {
        method: "POST",
        body: marine,
    }

    return fetchInterceptor(url, options)


};

export default AddMarineAPI;