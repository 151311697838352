import { Button, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useEffect, useMemo, useState } from "react";
import { header } from "../../../context/constant";
import GetMarineService from "../../../api/Marine/GetMarine/GetMarineService";
import toast, { Toaster } from "react-hot-toast";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import dayjs from "dayjs";
import { IMarine } from "./MarineType";
const Marine = () => {
  const [marineData, setMarineData] = useState<IMarine[]>();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const res = await GetMarineService({ header });
      if (res.success) {
        setMarineData(res.marinePolicies);
      }
    } catch (err: any) {
      const e = await err;
      toast.error(e.message);
    }
  };

  const columns = useMemo<MRT_ColumnDef<IMarine>[]>(
    () => [
      {
        accessorKey: "categoryName",
        header: "Category Name",
        size: 200,
      },
      {
        accessorKey: "productTypeName",
        header: "Product Type",
        size: 200,
      },
      {
        accessorKey: "industry",
        header: "Industry",
        size: 200,
      },
      {
        accessorKey: "industryDetails",
        header: "Industry Details",
        size: 200,
      },
      {
        accessorKey: "state",
        header: "State",
        size: 200,
      },
      {
        accessorKey: "city",
        header: "City",
        size: 200,
      },
      {
        accessorKey: "area",
        header: "Area",
        size: 200,
      },
      {
        accessorKey: "pincode",
        header: "Pin Code",
        size: 100,
      },
      {
        accessorKey: "address",
        header: "Address",
        size: 250,
      },
      {
        accessorKey: "fullName",
        header: "Full Name",
        size: 200,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 200,
      },
      {
        accessorKey: "gst",
        header: "GST",
        size: 100,
      },
      {
        accessorKey: "invoiceAmount",
        header: "Invoice Amount",
        size: 150,
      },
      {
        accessorKey: "incidentalCharges",
        header: "Incidental Charges",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.getValue<number>();
          return `${value}%`;
        },
      },
      {
        accessorKey: "totalInsuranceAmount",
        header: "Total Insurance Amount",
        size: 150,
      },

      {
        accessorKey: "finalPremium",
        header: "Final Premium",
        size: 150,
      },
      {
        accessorKey: "netPremium",
        header: "Net Premium",
        size: 150,
      },
      {
        accessorKey: "createdBy",
        header: "Created By",
        size: 150,
      },
      {
        accessorKey: "createdOn",
        header: "Created On",
        size: 200,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return <span>{dayjs(value).format("DD MMM YYYY")}</span>;
        },
      },
      {
        accessorKey: "invoiceNumber",
        header: "Invoice Number",
        size: 150,
      },
      {
        accessorKey: "invoiceDate",
        header: "Invoice Date",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return <span>{dayjs(value).format("DD MMM YYYY")}</span>;
        },
      },
      {
        accessorKey: "accountNumber",
        header: "Account Number",
        size: 150,
      },
      {
        accessorKey: "accountHolderName",
        header: "Account Holder Name",
        size: 200,
      },
      {
        accessorKey: "bankName",
        header: "Bank Name",
        size: 200,
      },
      {
        accessorKey: "branchName",
        header: "Branch Name",
        size: 200,
      },
      {
        accessorKey: "branchLocation",
        header: "Branch Location",
        size: 200,
      },
      {
        accessorKey: "IFSC",
        header: "IFSC",
        size: 200,
      },
      {
        accessorKey: "remarks",
        header: "Remarks",
        size: 250,
        muiTableBodyCellProps:{
          sx:{
            height: "122px",
            overflow: "auto",
            width: "100px",
            display: "flex"
          }
        }
      },
      {
        accessorKey: "commodityDescription",
        header: "Commodity Description",
        size: 200,
      },
      {
        accessorKey: "commodityType",
        header: "Commodity Type",
        size: 150,
      },
      {
        accessorKey: "transitStartingPoint",
        header: "Transit Start Point",
        size: 200,
      },
      {
        accessorKey: "destination",
        header: "Destination",
        size: 200,
      },
      {
        accessorKey: "isActive",
        header: "Status",
        size: 100,
        Cell: ({ cell }) => {
          const value = cell.getValue<boolean>();
          return value ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          );
        },
      },
    ],
    []
  );

  const handleClickEditMarine = (row: IMarine) => {
    navigate("edit", { state: row });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          Marine
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard{"/"}
              </Link>
              <span className="text-grey-600 text-sm">Marine</span>
            </div>
            <Link to="add">
              <Button
                type="button"
                className="w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
              >
                Add Marine
              </Button>
            </Link>
          </div>
        </Typography>
        <MaterialReactTable
          state={{ pagination }}
          columns={columns}
          data={marineData || []}
          enableRowActions
          onPaginationChange={setPagination}
          autoResetPageIndex={false}
          paginateExpandedRows={false}
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <div style={{ display: "flex", flexWrap: "nowrap" }}>
              <Tooltip title={"Edit Marine"}>
                <IconButton
                  color="primary"
                  aria-label={"Edit Marine"}
                  component="span"
                  onClick={() => {
                    handleClickEditMarine(row.original as IMarine);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 text-addButton"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
              
            </div>
          )}
        />
      </Paper>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
};
export default Marine;
