import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { addLiabilityEp as endpoint } from "../apiEndpoints";
import { IAddLiability } from "../ILiability";

const AddLiabilityAPI = async ({
    liability
}: IAddLiability) => {

    const url = endpoint()
    const options: FetchOptions = {
        method: "POST",
        body: liability,
    }

    return fetchInterceptor(url, options)


};

export default AddLiabilityAPI;