import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { setIn } from "final-form";
import React, { FC, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import toast, { Toaster } from "react-hot-toast";
import * as yup from "yup";
import {
  ALLOWED_FILE_TYPES,
  DAY_FORMAT,
  Document,
  header,
  MAX_FILE_SIZE,
  SafeKaroUser,
} from "../../../../context/constant";
import useGetProducts from "../../../../Hooks/Product/useGetProducts";
import { ICity } from "../../../Admin/City/ICity";
import useGetState from "../../../../Hooks/State/useGetState";
import GetCityByStateIdService from "../../../../api/City/GetCityByStateId/GetCityByStateIdService";
import { IArea } from "../../../Admin/Area/IArea";
import GetAreaByCityIdServices from "../../../../api/Area/GetAreaByCityId/GetAreaByCityIdServices";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  IAddMarineProps,
  IEditMarineProps,
  InitialValueProps,
  MarineProps,
} from "../MarineType";
import useIndustry from "../../../../Hooks/Industry/useIndustry";
import EditMarineService from "../../../../api/Marine/EditMarine/EditMarineService";
import AddMarineService from "../../../../api/Marine/AddMarine/AddMarineService";
import { useNavigate } from "react-router-dom";
import { IProducts } from "../../../Admin/Product/IProduct";
import { IIndustry } from "../../../Admin/industry/AddIndustry/IIndustry";
import dayjs from "dayjs";
import generateFormData from "../../uitils/generateFormData";
import { documentTypes } from "../../../Policy/IPolicyData";
const AddMarineForm: FC<MarineProps> = ({ propsData }) => {
  const [productData] = useGetProducts({ header, category: "Non Motor" });
  const fireProduct = productData.filter((ele) => {
    return ele.productName?.toLowerCase() === "marine";
  });
  const industryData = useIndustry();
  const [errors, setErrors] = useState<{ docName: string; file: string }[]>([
    { docName: "", file: "" },
  ]);
  const [documents, setDocuments] = useState<Document[]>([
    { docName: "", file: "" },
  ]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isHypothesis, setIsHypothesis] = useState(
    propsData?.hypothisiation === "YES" ? true : false
  );
  const states = useGetState();
  const [selectedStateId, setSelectedStateId] = useState(propsData?.stateId);
  const [selectedCityId, setSelectedCityId] = useState(propsData?.cityId);
  const [area, setArea] = useState<IArea[]>();
  const [cities, setCities] = useState<ICity[]>([]);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(
    propsData?.invoiceAmount
  );
  const [incidentalCharges, setIncidentalCharges] = useState(
    propsData?.incidentalCharges ?? 10
  );
  const [totalInsuranceAmount, setTotalInsuredAmount] = useState(
    propsData?.totalInsuranceAmount ?? 0
  );
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let userData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const navigate = useNavigate();
  useEffect(() => {
    if (incidentalCharges > 0) {
      const percentage = Number(incidentalCharges);
      const amount = Number(totalInvoiceAmount);
      const percentageAmount = Math.round((amount * percentage) / 100);
      setTotalInsuredAmount(amount + percentageAmount);
    }
  }, [incidentalCharges, totalInvoiceAmount]);
  const handleStateChange = async (newValue: any) => {
    setSelectedStateId(newValue._id);
    try {
      const res = await GetCityByStateIdService({
        header,
        stateId: newValue._id,
      });
      if (res.status === "success") {
        setCities(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const handleFileInputChange = (event: any, index: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileType = file.type;
      const fileSize = file.size;
      const newErrors = [...errors];
      if (!ALLOWED_FILE_TYPES.includes(fileType)) {
        newErrors[index] = {
          ...newErrors[index],
          file: "Invalid file type. Please upload an image or a PDF.",
        };
        setErrors(newErrors);
      } else if (fileSize > MAX_FILE_SIZE) {
        newErrors[index] = {
          ...newErrors[index],
          file: "File size exceeds the maximum limit",
        };
        setErrors(newErrors);
      } else {
        setErrorMessage("");
        const newDocuments = [...documents];
        newDocuments[index] = { ...newDocuments[index], file: file };
        setDocuments(newDocuments);
        if (newErrors[index]) {
          newErrors[index].file = "";
        }
        setErrors(newErrors);
      }
    }
  };
  const validateDocument = (document: Document, index: number) => {
    const isValidDocName = document.docName.trim() !== "";
    const isValidFile = document.file;
    validateField(index, "docName", document.docName);
    validateField(index, "file", document.file);
    return isValidDocName && isValidFile;
  };
  const validateField = (index: number, name: string, value: string) => {
    const newErrors = [...errors];
    if (name === "docName" || name === "file") {
      newErrors[index] = {
        ...newErrors[index],
        [name]: value === "" ? `${name} cannot be empty` : "",
      };
    }
    setErrors(newErrors);
  };
  const handleChangeDocumentName = (newValue: any, index: any) => {
    const updatedDocuments = documents.map((doc, i) =>
      i === index ? { ...doc, docName: newValue?.value! } : doc
    );
    setDocuments(updatedDocuments);
  };
  const handleClickAddDocument = () => {
    setDocuments([...documents, { docName: "", file: "" }]);
  };
  const handleClickDeleteDocument = (index: any) => {
    setDocuments((prevDocuments) =>
      prevDocuments.filter((_, i) => i !== index)
    );
  };
  const handleCityChange = async (newValue: any) => {
    setSelectedCityId(newValue._id);
    try {
      const res = await GetAreaByCityIdServices({
        header,
        id: newValue._id,
      });
      if (res.status === "success") {
        setArea(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const generateInitialData = () => {
    if (propsData) {
      const {
        address,
        email,
        fullName,
        industryDetails,
        gst,
        phone,
        pincode,
        state,
        city,
        hypothisiation,
        transitStartingPoint,
        accountNumber,
        accountHolderName,
        IFSC,
        bankName,
        remarks,
        finalPremium,
        netPremium,
        applicablePremiumRate,
        incidentalCharges,
        totalInsuranceAmount,
        invoiceAmount,
        invoiceDate,
        invoiceNumber,
        commodityType,
        commodityDescription,
        destination,
        startDate,
        endDate,
        branchLocation,
        branchName,
      } = propsData;
      const product: IProducts = {
        _id: propsData.productTypeId,
        categoryId: propsData.categoryId,
        categoryName: propsData.categoryName,
        productName: propsData.productTypeName,
      };
      const area: IArea = {
        _id: propsData.areaId ?? "",
        area: propsData.area ?? "",
        cityId: propsData.cityId ?? "",
        cityName: propsData.city,
        stateId: propsData.stateId ?? "",
        stateName: propsData.state,
      };
      const industry: IIndustry = {
        _id: propsData.industryId,
        industry: propsData.industry ?? "",
      };
      const result: InitialValueProps = {
        address,
        email,
        fullName,
        industryDetails,
        gst,
        phone,
        pincode,
        state,
        city,
        hypothisiation,
        product,
        industry,
        area,
        transitStartingPoint,
        accountNumber,
        accountHolderName,
        IFSC,
        bankName,
        remarks,
        finalPremium,
        netPremium,
        applicablePremiumRate,
        incidentalCharges,
        totalInsuranceAmount,
        invoiceAmount,
        invoiceDate,
        invoiceNumber,
        commodityType,
        commodityDescription,
        destination,
        startDate: dayjs(startDate).toISOString(),
        endDate: dayjs(endDate).toISOString(),
        branchLocation,
        branchName,
      };
      return result;
    }
  };
  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});
      return errors;
    }
  };
  const validationSchema = yup.object().shape({
    industry: yup.object().nullable().required("Industry is required"),
    industryDetails: yup
      .string()
      .required("Industry details are required")
      .min(5, "Industry details must be at least 5 characters")
      .max(500, "Industry details cannot exceed 500 characters"),
    transitStartingPoint: yup
      .string()
      .required("Transit starting point is required")
      .min(2, "Transit starting point must be at least 2 characters")
      .max(100, "Transit starting point cannot exceed 100 characters"),
    destination: yup
      .string()
      .required("Destination point is required")
      .min(2, "Destination must be at least 2 characters")
      .max(100, "Destination cannot exceed 100 characters"),
    state: yup
      .string()
      .required("State is required")
      .min(2, "State must be at least 2 characters")
      .max(100, "State cannot exceed 100 characters"),
    city: yup
      .string()
      .required("City is required")
      .min(1, "City must be at least 1 character")
      .max(100, "City cannot exceed 100 characters"),
    area: yup.object().required("Area is required"),
    address: yup
      .string()
      .required("Address is required")
      .min(5, "Address must be at least 5 characters")
      .max(255, "Address cannot exceed 255 characters"),
    pincode: yup
      .string()
      .required("Pin code is required")
      .matches(/^\d{6}$/, "Pin code must be a valid 6-digit number"),
    gst: yup.string().max(50),
    fullName: yup
      .string()
      .required("Full Name is required")
      .min(2, "Full Name must be at least 2 characters")
      .max(100, "Full Name cannot exceed 100 characters"),
    email: yup
      .string()
      .email("Enter a valid email address")
      .required("Email is required"),
    phone: yup
      .string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be a 10-digit number"),
    commodityType: yup
      .string()
      .required("Commodity Type is required")
      .min(3, "Commodity Type must be at least 3 characters")
      .max(100, "Commodity Type cannot exceed 100 characters"),
    commodityDescription: yup
      .string()
      .required("Commodity Description is required")
      .min(3, "Commodity Description must be at least 10 characters")
      .max(500, "Commodity Description cannot exceed 500 characters"),
    invoiceNumber: yup
      .string()
      .required("Invoice Number is required")
      .min(2, "Invoice Number must be at least 2 characters")
      .max(50, "Invoice Number cannot exceed 50 characters"),
    invoiceAmount: yup
      .number()
      .required("Invoice Amount is required")
      .positive("Invoice Amount must be a positive number")
      .min(1, "Invoice Amount must be at least 1"),
    netPremium: yup
      .number()
      .required("Net Premium is required")
      .positive("Net Premium must be a positive number")
      .min(1, "Net Premium must be at least 1"),
    finalPremium: yup
      .number()
      .required("Final Premium is required")
      .positive("Final Premium must be a positive number")
      .min(1, "Final Premium must be at least 1"),
    hypothisiation: yup
      .string()
      .required("Hypothecation is required")
      .oneOf(["YES", "NO"], "Select either YES or NO"),
    remarks: yup
      .string()
      .notRequired()
      .max(500, "Remarks cannot exceed 500 characters"),
    bankName: yup
      .string()
      .notRequired()
      .min(2, "Bank Name must be at least 2 characters")
      .max(100, "Bank Name cannot exceed 100 characters"),
    IFSC: yup
      .string()
      .notRequired()
      .min(1, "IFSC code must be 11 characters")
      .max(11, "IFSC code must be 11 characters"),
    accountHolderName: yup
      .string()
      .notRequired()
      .min(2, "Account Holder Name must be at least 2 characters")
      .max(100, "Account Holder Name cannot exceed 100 characters"),
    accountNumber: yup
      .string()
      .notRequired()
      .matches(/^\d{9,18}$/, "Account Number must be between 9 and 18 digits"),
  });
  const validate = validateFormValues(validationSchema);

  const callAddMarineApi = async (payload: IAddMarineProps) => {
    try {
      const res = await AddMarineService({ marine: generateFormData(payload) });
      if (res.status.toLowerCase() === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const callEditMarineApi = async (payload: IEditMarineProps) => {
    try {
      const res = await EditMarineService({
        id: propsData?._id!,
        marine: generateFormData(payload),
      });
      if (res.status.toLowerCase() === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const dateToStr = (date: string) => {
    return dayjs(date).format(DAY_FORMAT);
  };
  const onSubmit = async (data: any) => {
    if (!propsData) {
      const formValid = documents.every((doc, index) =>
        validateDocument(doc, index)
      );
      if (!formValid) {
        return;
      }
    }
    const {
      address,
      email,
      fullName,
      industryDetails,
      gst,
      phone,
      pincode,
      state,
      city,
      transitStartingPoint,
      accountNumber,
      accountHolderName,
      IFSC,
      bankName,
      remarks,
      finalPremium,
      netPremium,
      invoiceAmount,
      invoiceNumber,
      commodityDescription,
      commodityType,
      destination,
      hypothisiation,
      applicablePremiumRate,
      startDate,
      endDate,
      branchLocation,
      branchName,
    } = data;
    let { invoiceDate } = data;
    invoiceDate = dateToStr(invoiceDate);
    const { categoryId, categoryName, _id, productName } = fireProduct[0];
    const { area, _id: areaId } = data.area;
    const { _id: industryId, industry } = data.industry;
    let payload: IAddMarineProps = {
      stateId: selectedStateId!,
      cityId: selectedCityId!,
      categoryId: categoryId as string,
      categoryName: categoryName || "",
      productTypeId: _id || "",
      productTypeName: productName || "",
      fullName,
      industryDetails,
      area,
      areaId,
      gst,
      phone,
      pincode,
      state,
      city,
      transitStartingPoint,
      accountNumber,
      accountHolderName,
      IFSC,
      bankName,
      remarks,
      finalPremium,
      netPremium,
      applicablePremiumRate,
      incidentalCharges,
      totalInsuranceAmount,
      invoiceAmount,
      invoiceDate,
      invoiceNumber,
      commodityDescription,
      commodityType,
      destination,
      industryId,
      industry,
      address,
      email,
      hypothisiation,
      startDate: dayjs(startDate).format(DAY_FORMAT),
      endDate: dayjs(endDate).format(DAY_FORMAT),
      createdBy: userData.name,
      branchLocation,
      branchName,updatedBy: userData.name,
    };
    documents.forEach((doc: Document) => {
      if (doc.file) {
        payload[doc.docName] = doc.file;
      }
    });
  
    if (propsData) {
      callEditMarineApi(payload);
    } else {
      callAddMarineApi(payload);
    }
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={generateInitialData()}
        render={({ handleSubmit, submitError, submitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="industry">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="industry"
                          value={input.value || null}
                          options={industryData}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.industry || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=" Select Industry"
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="industryDetails">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Industry Details"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="transitStartingPoint">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Transit Starting Point"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="destination">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Destination Point"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="state">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="state"
                          value={input.value !== undefined ? input.value : null}
                          options={states}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.state || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue.state);
                            handleStateChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select State"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              {selectedStateId && cities && (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Field name="city">
                    {({ input, meta }) => (
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="city"
                          value={input.value !== undefined ? input.value : null}
                          options={cities}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.city || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue.city);
                            handleCityChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select City"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              )}
              {selectedCityId && area && (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Field name="area">
                    {({ input, meta }) => (
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="area"
                          value={input.value !== undefined ? input.value : null}
                          options={area}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.area || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select area"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              )}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="address">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Address"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="pincode">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Pin Code"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="gst">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter GST"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="fullName">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Name"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="email">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Email"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="phone">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Phone Number"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="commodityType">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Commodity Type"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Field name="commodityDescription">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Commodity Description"
                      type="text"
                      variant="outlined"
                      size="small"
                      multiline
                      rows={2}
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="invoiceNumber">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Invoice Number"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="invoiceDate">
                  {({ input, meta }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disablePast
                        label="Invoice Date"
                        inputFormat="DD/MM/YYYY"
                        value={input.value || null}
                        onChange={(date) => input.onChange(date)}
                        renderInput={(params: any) => (
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...params}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="invoiceAmount">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Invoice Amount"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      onChangeCapture={(e: any) =>
                        setTotalInvoiceAmount(e.target.value)
                      }
                    />
                  )}
                </Field>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="incidentalCharges">
                  {({ input, meta }) => (
                    <FormControl
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                    >
                      <InputLabel id="incidental-charges-label">
                        Incidental Charges
                      </InputLabel>
                      <Select
                        {...input}
                        labelId="incidental-charges-label"
                        label="Incidental Charges"
                        value={input.value || "10"}
                        onChange={(e) => {
                          input.onChange(e);
                          setIncidentalCharges(e.target.value);
                        }}
                      >
                        <MenuItem value="10">10%</MenuItem>
                        <MenuItem value="20">20%</MenuItem>
                        <MenuItem value="30">30%</MenuItem>
                      </Select>
                      {meta.touched && meta.error && (
                        <FormHelperText>{meta.error}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="totalInsuranceAmount">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Total Insurance Amount"
                      type="number"
                      variant="outlined"
                      size="small"
                      value={totalInsuranceAmount}
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="applicablePremiumRate">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Applicable Premium Rate"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="netPremium">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      size="small"
                      fullWidth
                      type="number"
                      label="Enter Net Premium"
                      variant="outlined"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="finalPremium">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      size="small"
                      type="number"
                      label="Enter Final Premium"
                      fullWidth
                      variant="outlined"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="hypothisiation">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="hypothisiation"
                          value={input.value || null}
                          options={["YES", "NO"]}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.state || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                            setIsHypothesis(newValue === "YES" ? true : false);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=" Select Hypothecation"
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Field name="remarks">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      id="remarks"
                      size="small"
                      multiline
                      rows={3}
                      fullWidth
                      label="Enter Remarks"
                      variant="outlined"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              {isHypothesis && (
                <>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="bankName">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Enter Bank Name"
                          variant="outlined"
                          size="small"
                          className="rounded-sm w-full"
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="branchName">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Enter Branch Name"
                          variant="outlined"
                          size="small"
                          className="rounded-sm w-full"
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="branchLocation">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Enter Bank Location"
                          variant="outlined"
                          size="small"
                          className="rounded-sm w-full"
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="IFSC">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Enter IFSC"
                          variant="outlined"
                          size="small"
                          className="rounded-sm w-full"
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="accountHolderName">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Enter Account Holder Name"
                          variant="outlined"
                          size="small"
                          className="rounded-sm w-full"
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="accountNumber">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Enter Account Number"
                          variant="outlined"
                          size="small"
                          type="number"
                          className="rounded-sm w-full"
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                </>
              )}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="startDate">
                  {({ input, meta }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        inputFormat="DD/MM/YYYY"
                        value={input.value || null}
                        onChange={(date) => input.onChange(date)}
                        renderInput={(params: any) => (
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...params}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="endDate">
                  {({ input, meta }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        inputFormat="DD/MM/YYYY"
                        value={input.value || null}
                        onChange={(date) => input.onChange(date)}
                        renderInput={(params: any) => (
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...params}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                </Field>
              </Grid>
              <Grid item md={12} mt={2}>
                <Button variant="outlined" onClick={handleClickAddDocument}>
                  Add More Document
                </Button>
                <Typography variant="body1" gutterBottom mr={4}>
                  {"Image should be <= 2MB."}
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                </Grid>
                {documents.map((doc, index) => (
                  <Grid item key={index} md={12} xs={12}>
                    <Grid container spacing={2} mt={1}>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Autocomplete
                          value={
                            documentTypes.find(
                              (option) => option.value === doc.docName
                            ) || null
                          }
                          onChange={(e, newValue) =>
                            handleChangeDocumentName(newValue!, index)
                          }
                          options={documentTypes}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="rounded-sm w-full"
                              size="small"
                              label="Select Document"
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                        {errors[index]?.docName && (
                          <span>{errors[index].docName}</span>
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <input
                          id={`file ${index}`}
                          type="file"
                          onChange={(e) => handleFileInputChange(e, index)}
                          style={{
                            border: "1px solid #c4c4c4",
                            padding: "5px",
                            width: "100%",
                            borderRadius: "5px",
                          }}
                        />
                        {errors[index]?.file && (
                          <span style={{ color: "red" }}>
                            {errors[index].file}
                          </span>
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        {documents.length !== 1 && (
                          <Tooltip title={"Delete Image"}>
                            <IconButton
                              color="primary"
                              aria-label={"Delete Image"}
                              component="span"
                              onClick={() => handleClickDeleteDocument(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {submitError && (
                  <div className="error text-safekaroDarkOrange">
                    {submitError}
                  </div>
                )}
                <Button variant="contained" type="submit">
                  submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};
export default AddMarineForm;
