import { lazy } from "react";

const PartnerDashboard = lazy(
    () => import("../Dashboard/partnerDashboard")
);
const BookingDashboard = lazy(
    () => import("../Dashboard/bookingDashboard")
);
const HrDashboard = lazy(() => import("../Dashboard/HrDashBoard"));
const OperationDashboard = lazy(
    () => import("../Dashboard/operationDashboard")
);
const RMDashboard = lazy(() => import("../Dashboard/RMDashboard"));
const ITDashboard = lazy(() => import("../Dashboard/ITDashboard"));
const AccountDashboard = lazy(
    () => import("../Dashboard/accountDashboard")
);
const Dashboard = lazy(() => import("../Dashboard/dashboard"));

export {
    PartnerDashboard,
    BookingDashboard,
    HrDashboard,
    OperationDashboard,
    RMDashboard,
    ITDashboard,
    AccountDashboard,
    Dashboard,
};
