import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editBurglaryEp as endpoint } from "../apiEndpoints";
import { IEditBurglary  } from "../IBurglary";

const EditBurglaryAPI = async ({
    id, burglary
}: IEditBurglary) => {
    const url = endpoint(id)
    const options: FetchOptions = {
        method: "PUT",
        body: burglary,
    }

    return fetchInterceptor(url, options)


};

export default EditBurglaryAPI;