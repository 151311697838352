import { IGetLiability } from "../ILiability";
import GetLiabilityAPI from "./GetLiabilityAPI";
const GetLiabilityService = async ({ header }: IGetLiability): Promise<any> => {
    try {
        const res = await GetLiabilityAPI({ header });
        return res;
    } catch (error) {
        throw error
    }
};

export default GetLiabilityService;