import { Button, Paper, Typography } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";

import { useEffect, useMemo, useState } from "react";
import { header, SafeKaroUser } from "../../context/constant";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import dayjs from "dayjs";
import { ITask } from "../../api/Task/ITask";
import GetTaskByEIdService from "../../api/Task/GetTaskByEmployeeId/GetTaskByEIdService";

const EmployeeTask = () => {
  const [tasks, setTasks] = useState<ITask[] | undefined>();
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let UserData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const id = UserData.id;
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = useMemo<MRT_ColumnDef<ITask>[]>(
    () => [
      {
        accessorKey: "state",
        header: "State",
        size: 200,
      },
      {
        accessorKey: "city",
        header: "City",
        size: 200,
      },
      {
        accessorKey: "area",
        header: "Area",
        size: 200,
      },
      {
        accessorKey: "investigation",
        header: "Investigation",
        size: 200,
      },
      {
        accessorKey: "employee",
        header: "Employee",
        size: 200,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 150,
      },
      {
        accessorKey: "createdOn",
        header: "Created On",
        size: 200,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return <span>{dayjs(value).format("DD MMM YYYY")}</span>;
        },
      },
      {
        header: "Is Active",
        accessorKey: "isActive",
        size: 50,
        Cell: ({ cell }) => {
          const value = cell.getValue<boolean>();
          return value ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          );
        },
      },
    ],
    []
  );

  const fetchTasks = async () => {
    try {
      const res = await GetTaskByEIdService({ header, id });

      if (res.success) {
        setTasks(res.tasks);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const generateDashBoardLink = () => {
    const role = UserData.role.toLowerCase();
    switch (role) {
      case "hr":
        return "/hr/dashboard";
      case "booking":
        return "/bookingdashboard";
      case "account":
        return "/accountdashboard";
      case "operation":
        return "/operationdashboard";
      case "rm":
        return "/rm/dashboard";
      default:
        return "/hr/dashboard";
    }
  };
  useEffect(() => {
    fetchTasks();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          Task Table
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to={generateDashBoardLink()}
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <span className="text-grey-600 text-sm"> Task</span>
            </div>
            <Link to="add-task">
              <Button
                type="button"
                className="w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
              >
                Add Task
              </Button>
            </Link>
          </div>
        </Typography>
        <MaterialReactTable
          state={{ pagination }}
          columns={columns}
          data={tasks || []}
          enableRowActions
          onPaginationChange={setPagination}
          autoResetPageIndex={false}
          paginateExpandedRows={false}
          positionActionsColumn="last"
        />
      </Paper>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
};

export default EmployeeTask;
