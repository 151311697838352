import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { addBurglaryEp as endpoint } from "../apiEndpoints";
import { IAddBurglary } from "../IBurglary";

const AddBurglaryAPI = async ({
burglary
}: IAddBurglary) => {

    const url = endpoint()
    const options: FetchOptions = {
        method: "POST",
        body: burglary,
    }

    return fetchInterceptor(url, options)


};

export default AddBurglaryAPI;