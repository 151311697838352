import React from "react";
import { MenuItem, OperationMenuItems, SidebarProps } from "./SideBarMenusList";
import DynamicSideBar from "./DynamicSideBar";
const menuItems: MenuItem[] = OperationMenuItems;
const OperationSideBar: React.FC<SidebarProps> = ({
  isSidebarOpen,
  setSidebarOpen,
}) => {
  return (
    <DynamicSideBar
      isSidebarOpen={isSidebarOpen}
      setSidebarOpen={setSidebarOpen}
      menuList={menuItems}
    />
  );
};
export default OperationSideBar;
