
import fetchInterceptor, { FetchOptions } from '../../../utils/fetchInterceptor ';
import { IGetWorkmenCommunication } from '../IWorkmenCommunication';
import { getWorkmenCommunicationEp as endpoint } from '../apiEndpoints';
const GetWorkmenCommunicationAPI = async ({ header }: IGetWorkmenCommunication) => {
    const url = endpoint()
    const options: FetchOptions = {
        method: "GET",
        headers: header,
    }

    return fetchInterceptor(url, options)

};

export default GetWorkmenCommunicationAPI;