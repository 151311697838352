import { lazy } from "react";

const UploadPolicy = lazy(() => import("../Policy/UploadPolicy/UploadPolicy"));
const GetRenewals = lazy(() => import("../Policy/Renewals/GetRenewals"));
const PublishedPolicyPage = lazy(() => import("../Policy/PublishedPolicy/PublishedPolicyPage"));
const RMGetMotorPolicies = lazy(() => import("../Policy/GetMotorPolicies/RMGetMotorPolicies"));
const PolicyPDF = lazy(() => import("../Policy/PolicyPDF/PolicyPDF"));
const Filter = lazy(() => import("../Filter/FilterPolicy/Filter"));
const ViewPolicyDetails = lazy(() => import("../Policy/ViewPolicy/ViewPolicyDetails"));
const GetArchiveMotorPolicies = lazy(() => import("../Policy/GetMotorPolicies/GetArchiveMotorPolicies"));
const EditCommission = lazy(() => import("../Policy/EditCommsion/editCommison"));
const ViewPolicy = lazy(() => import("../Policy/ViewPolicy/ViewPolicy"));
const AddMotorPolicy = lazy(() => import("../Policy/AddPolicy/AddPolicy"));
const EditMotorPolicy = lazy(() => import("../Policy/EditPolicy/EditPolicy"));
const GetMotorPolicies = lazy(() => import("../Policy/GetMotorPolicies/GetMotorPolicies"));
const PolicyDispute = lazy(() => import("../Policy/PolicyDispute/PolicyDispute"));
const DisputedPolicyPage = lazy(() => import("../Policy/PolicyDispute/DisputedPolicyPage"));

export {
    UploadPolicy,
    GetRenewals,
    PublishedPolicyPage,
    RMGetMotorPolicies,
    PolicyPDF,
    Filter,
    ViewPolicyDetails,
    GetArchiveMotorPolicies,
    EditCommission,
    ViewPolicy,
    AddMotorPolicy,
    EditMotorPolicy,
    GetMotorPolicies,
    PolicyDispute,
    DisputedPolicyPage,
};
