import { IEditLiability } from "../ILiability";
import EditLiabilityAPI from "./EditLiabilityAPI";

const EditLiabilityService = async ({ id, liability }: IEditLiability): Promise<any> => {
    try {
        const res = await EditLiabilityAPI({
            id,
            liability
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default EditLiabilityService;
