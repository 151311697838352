import { IEditWorkmenCommunication } from "../IWorkmenCommunication";
import EditWorkmenCommunicationAPI from "./EditWorkmenCommunicationAPI";

const EditWorkmenCommunicationService = async ({ id, workmenCommunication }: IEditWorkmenCommunication): Promise<any> => {
    try {
        const res = await EditWorkmenCommunicationAPI({
            id,
            workmenCommunication
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default EditWorkmenCommunicationService;
