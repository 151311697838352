import { Paper, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AddBurglaryForm from "./AddBurglaryForm";
import { IBurglary } from "../../../../api/Burglary/IBurglary";
const AddBurglary = () => {
  const loc = useLocation();
  const data = loc.state as IBurglary;
  const title = data ? "Edit Burglary" : "Add Burglary";
  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          NON MOTOR
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <Link to="/burglary" className="text-addButton font-bold text-sm">
                Burglary /
              </Link>
              <span className="text-grey-600 text-sm"> {title}</span>
            </div>
          </div>
        </Typography>
        {data ? <AddBurglaryForm propsData={data} /> : <AddBurglaryForm />}
      </Paper>
    </div>
  );
};

export default AddBurglary;
