import { IGetMarine } from "../IMarine";
import GetMarineAPI from "./GetMarineAPI";
const GetMarineService = async ({ header }: IGetMarine): Promise<any> => {
    try {
        const res = await GetMarineAPI({ header });
        return res;
    } catch (error) {
        throw error
    }
};

export default GetMarineService;