export const getAllIndustryEp = () => {
    return ("/api/non-motor/industry")
}
export const addIndustryEp = () => {
    return ("/api/non-motor/industry")
}

export const editIndustryEp = (id: string) => {
    return (`/api/non-motor/industry/${id}`)
}
export const getIndustryByIdEp = (id: string) => {
    return (`/api/non-motor/industry/${id}`)
}
export const deleteIndustryEp = (id: string) => {
    return (`/api/non-motor/industry/${id}`)
}