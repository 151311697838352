export const getBurglaryEp = () => {
    return ("/api/non-motor/burglary-lead")
}
export const getBurglaryByIdEp = (burglaryId: string) => {
    return (`/api/non-motor/burglary-lead/${burglaryId}`)
}
export const editBurglaryEp = (burglaryId: string) => {
    return (`/api/non-motor/burglary-lead/${burglaryId}`)
}
export const deleteBurglaryEp = (burglaryId: string) => {
    return (`/api/non-motor/burglary-lead/${burglaryId}`)
}
export const addBurglaryEp = () => {
    return ("/api/non-motor/burglary-lead")
}