import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { getTaskByEmployeeIdEp as endpoint } from '../apiEndPoints';
import { IGetTaskProps } from "../ITask";


const GetTaskByEIdAPI = async ({ header,id }: IGetTaskProps) => {
  const url = endpoint(id!)
  const options: FetchOptions = {
    method: "GET",
    headers: header,
  }

  return fetchInterceptor(url, options)

 
};

export default GetTaskByEIdAPI;
