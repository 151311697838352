import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { addWorkmenCommunicationEp as endpoint } from "../apiEndpoints";
import { IAddWorkmenCommunication } from "../IWorkmenCommunication";

const AddWorkmenCommunicationAPI = async ({
    workmenCommunication
}: IAddWorkmenCommunication) => {

    const url = endpoint()
    const options: FetchOptions = {
        method: "POST",
        body: workmenCommunication,
    }

    return fetchInterceptor(url, options)
};

export default AddWorkmenCommunicationAPI;