import { Paper, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AddMarineForm from "./AddMarineForm";
import { IMarine } from "../MarineType";

const AddMarine = () => {
  const loc = useLocation();
  const data = loc.state as IMarine;
  const title = data ? "Edit Marine" : "Add Marine";
  return (
    <div className="bg-blue-200 md:p-7 p-2 h-[100%]">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          NON MOTOR
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <Link to="/marine" className="text-addButton font-bold text-sm">
                Marine /
              </Link>

              <span className="text-grey-600 text-sm"> {title}</span>
            </div>
          </div>
        </Typography>
        {data ? <AddMarineForm propsData={data} /> : <AddMarineForm />}
      </Paper>
    </div>
  );
};

export default AddMarine;
