import React from "react";
import { MenuItem, RMmenuItems, SidebarProps } from "./SideBarMenusList";
import DynamicSideBar from "./DynamicSideBar";

const menuItems: MenuItem[] = RMmenuItems;
const RMSidebar: React.FC<SidebarProps> = ({
  isSidebarOpen,
  setSidebarOpen,
}) => {
  return (
    <DynamicSideBar
      isSidebarOpen={isSidebarOpen}
      setSidebarOpen={setSidebarOpen}
      menuList={menuItems}
    />
  );
};

export default RMSidebar;
