import { getReqPayload } from "../NatureOfWorkTypes";
import GetOccupancyAPI from "./GetNatureOfWorkAPI";

const GetOccupancyService = async ({ header }: getReqPayload): Promise<any> => {
    try {
        const res = await GetOccupancyAPI({ header });
        return res;
    } catch (error) {
        throw error
    }
};

export default GetOccupancyService;