export const getNatureOfWorkEp = ()=>{
    return('/api/natureOfWork')
}
export const postNatureOfWorkEp = ()=>{
    return('/api/natureOfWork')
}
export const editNatureOfWorkEp = (id:string)=>{
    return(`/api/natureOfWork/${id}`)
}
export const getByIdNatureOfWorkEp = (Id:string)=>{
    return(`/api/natureOfWork/${Id}`)
}