export const getMarineEp = () => {
    return ("/api/non-motor/marine-lead")
}
export const getMarineByIdEp = (marineId: string) => {
    return (`/api/non-motor/marine-lead/${marineId}`)
}
export const editMarineEp = (marineId: string) => {
    return (`/api/non-motor/marine-lead/${marineId}`)
}
export const deleteMarineEp = (marineId: string) => {
    return (`/api/non-motor/marine-lead/${marineId}`)
}
export const addMarineEp = () => {
    return ("/api/non-motor/marine-lead")
}