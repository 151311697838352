import { IEditMarine } from "../IMarine";
import EditMarineAPI from "./EditMarineAPI";

const EditMarineService = async ({ id, marine }: IEditMarine): Promise<any> => {
    try {
        const res = await EditMarineAPI({
            id,
            marine
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default EditMarineService;
