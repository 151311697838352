export const getWorkmenCommunicationEp = () => {
    return ("/api/non-motor/workmen-communication-lead")
}
export const getWorkmenCommunicationByIdEp = (workmenCommunicationId: string) => {
    return (`/api/non-motor/workmen-communication-lead/${workmenCommunicationId}`)
}
export const editWorkmenCommunicationEp = (workmenCommunicationId: string) => {
    return (`/api/non-motor/workmen-communication-lead/${workmenCommunicationId}`)
}
export const deleteWorkmenCommunicationEp = (workmenCommunicationId: string) => {
    return (`/api/non-motor/workmen-communication-lead/${workmenCommunicationId}`)
}
export const addWorkmenCommunicationEp = () => {
    return ("/api/non-motor/workmen-communication-lead")
}