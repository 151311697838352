
import fetchInterceptor, { FetchOptions } from '../../../utils/fetchInterceptor ';
import { IGetMarine } from '../IMarine';
import { getMarineEp as endpoint } from '../apiEndpoints';
const GetMarineAPI = async ({ header }: IGetMarine) => {
    const url = endpoint()
    const options: FetchOptions = {
        method: "GET",
        headers: header,
    }

    return fetchInterceptor(url, options)

};

export default GetMarineAPI;