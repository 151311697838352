import { Box, CircularProgress } from "@mui/material";
import React, { Suspense } from "react";

interface SuspenseWrapperProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

const SuspenseWrapper: React.FC<SuspenseWrapperProps> = ({ children }) => {
  const fallback = (
    <Box sx={{ display: "flex justifyCenter" }}>
      <CircularProgress />
    </Box>
  );
  return <Suspense fallback={fallback}>{children}</Suspense>;
};

export default SuspenseWrapper;
