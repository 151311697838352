import { IAddLiability } from "../ILiability";
import AddLiabilityAPI from "./AddLiabilityAPI";

const AddLiabilityService = async ({ liability }: IAddLiability): Promise<any> => {
    try {
        const res = await AddLiabilityAPI({
            liability
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default AddLiabilityService;
