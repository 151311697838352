import SignUp from "./Auth/Signup";
import Signin from "./Auth/Signin";
import Page403 from "./Auth/Page403";
import AddBookingRequest from "./components/Booking/AddBookingRequest/AddBookingRequest";
import BookingRequests from "./components/Booking/BookingRequests/BookingRequests";
import AddLead from "./components/Partner/AddLead/AddLead";
import Leads from "./components/Partner/Leads/leads";
import NewLeads from "./components/Partner/Leads/Newleads";
import EditLead from "./components/Partner/EditLead/editLead";
import AddQuotation from "./components/Partner/Quotation/AddQuotation/AddQuotation";
import ViewQuotation from "./components/Partner/Quotation/ViewQuotation/ViewQuotation";
import NewBookingRequests from "./components/Booking/BookingRequests/NewBookingRequests";
import ManageCards from "./components/Partner/ManageCard/manageCards";
import ViewCardHistory from "./components/Partner/ManageCard/ViewCardHistory";
import RejectionPolicies from "./components/Booking/BookingRequests/RejectionPolicies";
import Notification from "./components/Notification/Notification";
import ProfilePage from "./components/Profile/ProfilePage";
import AcceptedBooking from "./components/Booking/AcceptedBooking/AcceptedBooking";
import FilterPayOut from "./components/Filter/FilterPayOut/FilterPayOut";
import FilterPayIn from "./components/Filter/FilterPayIn/FilterPayIn";
import MonthlyFilterPayOut from "./components/Filter/FilterPayOut/MonthlyFilterPayOut";
import MonthlyFilterPayIn from "./components/Filter/FilterPayIn/MonthlyFilterPayIn";
import MonthlyCompanyFilterPayOut from "./components/Filter/FilterPayOut/MonthlyCompanyFilterPayOut";
import MonthlyCompanyFilterPayIn from "./components/Filter/FilterPayIn/MonthlyCompanyFilterPayIn";
import CompanyFilterPayIn from "./components/Filter/FilterPayIn/CompanyFilterPayIn";
import CompanyFilterPayOut from "./components/Filter/FilterPayOut/CompanyFilterPayOut";
import RmLead from "./components/Rm/RmLead";
import RmRequestedBooking from "./components/Booking/BookingRequests/RmRequestedBooking";
import MonthlyPayout from "./components/TreeView/PayOut/MonthlyPayout";
import FilterRecievedPayInAmount from "./components/Filter/FilterRecievedPayInAmount/FilterRecievedPayInAmount";
import CompanyFilterRecievedPayIn from "./components/Filter/FilterRecievedPayInAmount/CompanyFilterRecievedPayIn";
import MonthlyFilterRecievedPayIn from "./components/Filter/FilterRecievedPayInAmount/MonthlyFilterRecievedPayIn";
import MonthlyCompanyFilterRecievedPayIn from "./components/Filter/FilterRecievedPayInAmount/MonthlyCompanyFilterRecievedPayIn";
import FilterPayInBalance from "./components/Filter/FilterPayInBalance/FilterPayInBalance";
import CompanyFilterPayInBalance from "./components/Filter/FilterPayInBalance/CompanyFilterPayInBalance";
import MonthlyCompanyFilterPayInBalance from "./components/Filter/FilterPayInBalance/MonthlyCompanyFilterPayInBalance";
import MonthlyFilterPayInBalance from "./components/Filter/FilterPayInBalance/MonthlyFilterPayInBalance";
import FilterPayInLeftDistributed from "./components/Filter/FilterPayInLeftDistributed/FilterPayInLeftDistributed";
import CompanyFilterPayInLeftDistributed from "./components/Filter/FilterPayInLeftDistributed/CompanyFilterPayInLeftDistributed";
import MonthlyCompanyFilterPayInLeftDistributed from "./components/Filter/FilterPayInLeftDistributed/MonthlyCompanyFilterPayInLeftDistributed";
import MonthlyFilterPayInLeftDistributed from "./components/Filter/FilterPayInLeftDistributed/MonthlyFilterPayInLeftDistributed";
import MonthlyPaidFilterPayOut from "./components/Filter/FilterPaidPayOut/MonthlyPaidFilterPayOut";
import MonthlyCompanyPaidFilterPayOut from "./components/Filter/FilterPaidPayOut/MonthlyCompanyPaidFilterPayOut";
import MonthlyFilterPayOutBalance from "./components/Filter/FilterPayOutBalance/MonthlyFilterPayOutBalance";
import MonthlyCompanyFilterPayOutBalance from "./components/Filter/FilterPayOutBalance/MonthlyCompanyFilterPayOutBalance";
import MonthlyFilterPayOutLeftDistributed from "./components/Filter/FilterPayOutLeftDistributed/MonthlyFilterPayOutLeftDistributed";
import MonthlyCompanyFilterPayOutLeftDistributed from "./components/Filter/FilterPayOutLeftDistributed/MonthlyCompanyFilterPayOutLeftDistributed";
import YearlyPayout from "./components/TreeView/PayOut/YearlyPayOut";
import YearlyPayIn from "./components/TreeView/PayIn/YearlyPayIn";
import MonthlyPayIn from "./components/TreeView/PayIn/MonthlyPayIn";
import FilterPaidPayOut from "./components/Filter/FilterPaidPayOut/FilterPaidPayOut";
import FilterPayOutBalance from "./components/Filter/FilterPayOutBalance/FilterPayOutBalance";
import CompanyFilterPayOutBalance from "./components/Filter/FilterPayOutBalance/CompanyFilterPayOutBalance";
import FilterPayOutLeftDistributed from "./components/Filter/FilterPayOutLeftDistributed/FilterPayOutLeftDistributed";
import CompanyFilterPayOutLeftDistributed from "./components/Filter/FilterPayOutLeftDistributed/CompanyFilterPayOutLeftDistributed";
import CompanyFilterPayOutPaid from "./components/Filter/FilterPaidPayOut/CompanyFilterPayOutPaid";
import FilterPartnerMonthlyNetPremium from "./components/Filter/FilterNetPerminum/FilterPartnerMonthlyNetPremium";
import MonthlyFinalPremium from "./components/Filter/FilterFinalPreminum/MonthlyFinalPremium";
import FilterMonthlyBrokerFinalPremium from "./components/Filter/FilterFinalPreminum/FilterMonthlyBrokerFinalPremium";
import FilterPartnerMonthlyFinalPremium from "./components/Filter/FilterFinalPreminum/FilterPartnerMonthlyFinalPremium";
import FilterMonthlyBrokerNetPremium from "./components/Filter/FilterNetPerminum/FilterMonthlyBrokerNetPerimum";
import NetPremium from "./components/Filter/FilterNetPerminum/NetPerimum";
import FilterBrokerNetPremium from "./components/Filter/FilterNetPerminum/FilterBrokerNetPerimum";
import FilterPartnerNetPremium from "./components/Filter/FilterNetPerminum/FilterPartnerNetPremium";
import FilterPartnerFinalPremium from "./components/Filter/FilterFinalPreminum/FilterPartnerFinalPremium";
import FinalPerimum from "./components/Filter/FilterFinalPreminum/FinalPerimum";
import FilterBrokerFinalPerimum from "./components/Filter/FilterFinalPreminum/FilterBrokerFinalPerimum";
import YearlyNetPremium from "./components/TreeView/NetPremium/YearlyNetPremium";
import YearlyFinalNetPremium from "./components/TreeView/FinalNetPremium/YearlyFinalNetPremium";
import MonthlyNetPremium from "./components/TreeView/NetPremium/MonthlyNetPremium";
import MonthlyFinalNetPremium from "./components/TreeView/FinalNetPremium/MonthlyFinalPremium";
import MonthlyAllNetPerimum from "./components/Filter/FilterNetPerminum/MonthlyAllNetPerimum";
import BlogCategories from "./components/Website/BlogCategory/BlogCategories/Categories";
import AddBlogCategory from "./components/Website/BlogCategory/AddBlogCategory/AddBlogCategory";
import Blogs from "./components/Website/Blog/Blogs/Blogs";
import AddBlog from "./components/Website/Blog/AddBlog/AddBlog";
import News from "./components/Website/News/News/News";
import AddNews from "./components/Website/News/AddNews/AddNews";
import AddNewsCategory from "./components/Website/NewsCategory/AddNewsCategory/AddNewsCategory";
import NewsCategories from "./components/Website/NewsCategory/NewsCategories/NewsCategories";
import HolidayList from "./components/HR/Holidays/HolidayList";
import AddHoliday from "./components/HR/Holidays/AddHoliday";
import Attendance from "./components/HR/Attendance/AttendanceRecord/Attendance";
import EmployeeAttendance from "./components/HR/Attendance/AttendanceRecord/EmployeeAttendance";
import AddAttendance from "./components/HR/Attendance/AddAttendance/AddAttendance";
import MarkAttendance from "./components/HR/Attendance/MarkAttendance/MarkAttendance";
import Liability from "./components/nonMotor/Liability/Liability";
import Marine from "./components/nonMotor/Marine/Marine";
import Burglary from "./components/nonMotor/Burglary/Burglary";
import AddLiability from "./components/nonMotor/Liability/AddLiability/AddLiability";
import AddBurglary from "./components/nonMotor/Burglary/AddBurglary/AddBurglary";
import AddMarine from "./components/nonMotor/Marine/AddMarine/AddMarine";
import WorkmenCommunication from "./components/nonMotor/WorkmenCommunication/WorkmenCommunication";
import AddWorkMenCommunication from "./components/nonMotor/WorkmenCommunication/AddWorkMenCommunication/AddWorkMenCommunication";
import EmployeeTask from "./components/Task/EmployeeTask";
import RequestForQueue from "./components/Partner/RequestForQueue/RequestForQueue";
import AddReqQueue from "./components/Partner/RequestForQueue/AddReqQueue/AddReqQueue";
import SuspenseWrapper from "./utils/ui/SpanceWrapper";
import {
  PartnerDashboard,
  BookingDashboard,
  HrDashboard,
  OperationDashboard,
  RMDashboard,
  ITDashboard,
  AccountDashboard,
  Dashboard,
} from "./components/lazyFiles/Dashboardds";
import {
  AddAccounts,
  Accounts,
  CompareBrokerPayment,
  CreditDebits,
  AddCreditDebits,
  UpdatePayment,
  ManagePartnerPayment,
  ComparePartnerPayment,
  ViewAccountCreditDebitsDetails,
  ViewCreditDebitByBrokerCard,
  ViewCreditDebitByPartnerCard,
  TdsPayInManage,
  TdsPayOutManage,
  BrokerCredit,
  PartnerDebit,
  TeamAccounts,
  ExcelPayIn,
  ExcelPayout,
} from "./components/lazyFiles/Accountds";
import {
  Roles,
  AddRole,
  Products,
  AddProduct,
  AddPolicyType,
  PolicyTypes,
  CaseTypes,
  AddCaseType,
  PayInUploadByExcel,
  PayOutUploadByExcel,
  AddProductSubType,
  ProductSubTypes,
  AddCompany,
  Companies,
  Brokers,
  AddBroker,
  AddBranch,
  Branches,
  Categories,
  AddCategory,
  AddFuelType,
  FuelTypes,
  AddMake,
  Makes,
  Models,
  AddModel,
  Teams,
  AddTeam,
  Percentage,
  Partners,
  Operations,
  RM,
  Bookings,
  RmTeams,
  Ranks,
  AddRank,
  Industry,
  AddIndustry,
  State,
  City,
  Area,
  AddState,
  AddCity,
  AddArea,
  Task,
  AddTask,
  Investigation,
  AddInvestigation,
  Occupancy,
  AddOccupancy,
  NatureOfWork,
  AddNatureOfWork,
} from "./components/lazyFiles/Adminds";
import {
  UploadPolicy,
  GetRenewals,
  PublishedPolicyPage,
  RMGetMotorPolicies,
  PolicyPDF,
  Filter,
  ViewPolicyDetails,
  GetArchiveMotorPolicies,
  EditCommission,
  ViewPolicy,
  AddMotorPolicy,
  EditMotorPolicy,
  GetMotorPolicies,
  PolicyDispute,
  DisputedPolicyPage,
} from "./components/lazyFiles/PolicyLazy";
const routes = [
  {
    path: "/",
    children: [
      {
        path: "",
        element: <Signin />,
      },
    ],
  },
  {
    path: "/signup",
    children: [
      {
        path: "",
        element: <SignUp />,
      },
    ],
  },
  {
    path: "/rm",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <RM />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "/team-booking",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Bookings />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "/accounts",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <TeamAccounts />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "/partners",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Partners />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "/operations",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Operations />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "dashboard",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Dashboard />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "lead",
    children: [
      {
        path: "",
        element: <Leads />,
      },
      {
        path: "new",
        element: <NewLeads />,
      },
      {
        path: "add",
        element: <AddLead />,
      },
      {
        path: "/lead/:leadId/edit",
        element: <EditLead />,
      },
      {
        path: "/lead/:leadId/quotation",
        element: <AddQuotation />,
      },
      {
        path: "/lead/:leadId/quotation/view",
        element: <ViewQuotation />,
      },
      {
        path: "/lead/rq-queue",
        element: <RequestForQueue />,
      },
      {
        path: "/lead/edit-request",
        element: <AddReqQueue />,
      },
      {
        path: "/lead/add-request",
        element: <AddReqQueue />,
      },
    ],
  },
  {
    path: "role",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Roles />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddRole />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/role/:roleId/edit",
        element: (
          <SuspenseWrapper>
            <AddRole />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "state",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <State />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add-state",
        element: (
          <SuspenseWrapper>
            <AddState />
          </SuspenseWrapper>
        ),
      },
      {
        path: "edit-state",
        element: (
          <SuspenseWrapper>
            <AddState />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "occupancy",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Occupancy />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add-occupancy",
        element: (
          <SuspenseWrapper>
            <AddOccupancy />
          </SuspenseWrapper>
        ),
      },
      {
        path: "edit-occupancy",
        element: (
          <SuspenseWrapper>
            <AddOccupancy />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "natureOfWork",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <NatureOfWork />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add-natureOfWork",
        element: (
          <SuspenseWrapper>
            <AddNatureOfWork />
          </SuspenseWrapper>
        ),
      },
      {
        path: "edit-natureOfWork",
        element: (
          <SuspenseWrapper>
            <AddNatureOfWork />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "city",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <City />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add-city",
        element: (
          <SuspenseWrapper>
            <AddCity />
          </SuspenseWrapper>
        ),
      },
      {
        path: "edit-city",
        element: (
          <SuspenseWrapper>
            <AddCity />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "area",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Area />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add-area",
        element: (
          <SuspenseWrapper>
            <AddArea />
          </SuspenseWrapper>
        ),
      },
      {
        path: "edit-area",
        element: (
          <SuspenseWrapper>
            <AddArea />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "task",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Task />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add-task",
        element: (
          <SuspenseWrapper>
            <AddTask />
          </SuspenseWrapper>
        ),
      },
      {
        path: "edit-task",
        element: (
          <SuspenseWrapper>
            <AddTask />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "investigation",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Investigation />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add-investigation",
        element: (
          <SuspenseWrapper>
            <AddInvestigation />
          </SuspenseWrapper>
        ),
      },
      {
        path: "edit-investigation",
        element: (
          <SuspenseWrapper>
            <AddInvestigation />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "website",
    children: [
      {
        path: "newscategory/add",
        element: <AddNewsCategory />,
      },
      {
        path: "newscategory/:categoryId/edit",
        element: <AddNewsCategory />,
      },
      {
        path: "newscategories",
        element: <NewsCategories />,
      },
      {
        path: "news",
        element: <News />,
      },
      {
        path: "news/add",
        element: <AddNews />,
      },
      {
        path: "news/:blogId/edit",
        element: <AddNews />,
      },
      {
        path: "blogcategory/add",
        element: <AddBlogCategory />,
      },
      {
        path: "blogcategory/:categoryId/edit",
        element: <AddBlogCategory />,
      },
      {
        path: "blogcategories",
        element: <BlogCategories />,
      },
      {
        path: "blogs",
        element: <Blogs />,
      },
      {
        path: "blogs/add",
        element: <AddBlog />,
      },
      {
        path: "blogs/:blogId/edit",
        element: <AddBlog />,
      },
    ],
  },
  {
    path: "profile",
    children: [
      {
        path: "",
        element: <ProfilePage />,
      },
    ],
  },
  {
    path: "finalpremium",
    children: [
      {
        path: "",
        element: <FinalPerimum />,
      },
      {
        path: "/finalpremium/broker/company/:brokerId",
        element: <FilterBrokerFinalPerimum />,
      },
      {
        path: "/finalpremium/partner/company/:partnerId",
        element: <FilterPartnerFinalPremium />,
      },
      {
        path: "monthly",
        element: <MonthlyFinalPremium />,
      },
      {
        path: "/finalpremium/monthly/broker/company/:startDate/:endDate/:brokerId",
        element: <FilterMonthlyBrokerFinalPremium />,
      },
      {
        path: "/finalpremium/monthly/partner/company/:startDate/:endDate/:partnerId",
        element: <FilterPartnerMonthlyFinalPremium />,
      },
    ],
  },
  {
    path: "netpremium",
    children: [
      {
        path: "",
        element: <NetPremium />,
      },
      {
        path: "/netpremium/broker/company/:brokerId",
        element: <FilterBrokerNetPremium />,
      },
      {
        path: "/netpremium/partner/company/:partnerId",
        element: <FilterPartnerNetPremium />,
      },
      {
        path: "monthly_preminum",
        element: <MonthlyAllNetPerimum />,
      },
      {
        path: "monthly",
        element: <MonthlyNetPremium />,
      },
      {
        path: "/netpremium/monthly/broker/company/:startDate/:endDate/:brokerId",
        element: <FilterMonthlyBrokerNetPremium />,
      },
      {
        path: "/netpremium/monthly/partner/company/:startDate/:endDate/:partnerId",
        element: <FilterPartnerMonthlyNetPremium />,
      },
    ],
  },
  {
    path: "payins",
    children: [
      {
        path: "",
        element: <FilterPayIn />,
      },
      {
        path: "/payins/company/:brokerId",
        element: <CompanyFilterPayIn />,
      },
      {
        path: "monthly",
        element: <MonthlyFilterPayIn />,
      },
      {
        path: "/payins/monthly/company/:startDate/:endDate/:brokerId",
        element: <MonthlyCompanyFilterPayIn />,
      },
      {
        path: "recieved",
        element: <FilterRecievedPayInAmount />,
      },
      {
        path: "/payins/recieved/company/:brokerId",
        element: <CompanyFilterRecievedPayIn />,
      },
      {
        path: "recieved/monthly",
        element: <MonthlyFilterRecievedPayIn />,
      },
      {
        path: "/payins/monthly/recieved/company/:startDate/:endDate/:brokerId",
        element: <MonthlyCompanyFilterRecievedPayIn />,
      },
      {
        path: "balance",
        element: <FilterPayInBalance />,
      },
      {
        path: "/payins/balance/company/:brokerId",
        element: <CompanyFilterPayInBalance />,
      },
      {
        path: "/payins/balance/monthly/company/:startDate/:endDate/:brokerId",
        element: <MonthlyCompanyFilterPayInBalance />,
      },
      {
        path: "balance/monthly",
        element: <MonthlyFilterPayInBalance />,
      },
      {
        path: "leftDistributed",
        element: <FilterPayInLeftDistributed />,
      },
      {
        path: "/payins/leftDistributed/company/:brokerId",
        element: <CompanyFilterPayInLeftDistributed />,
      },
      {
        path: "leftDistributed/monthly",
        element: <MonthlyFilterPayInLeftDistributed />,
      },
      {
        path: "/payins/leftDistributed/monthly/company/:startDate/:endDate/:brokerId",
        element: <MonthlyCompanyFilterPayInLeftDistributed />,
      },
    ],
  },
  {
    path: "payouts",
    children: [
      {
        path: "",
        element: <FilterPayOut />,
      },
      {
        path: "paid",
        element: <FilterPaidPayOut />,
      },
      {
        path: "/payouts/paid/company/:partnerId",
        element: <CompanyFilterPayOutPaid />,
      },
      {
        path: "balance",
        element: <FilterPayOutBalance />,
      },
      {
        path: "/payouts/balance/company/:partnerId",
        element: <CompanyFilterPayOutBalance />,
      },
      {
        path: "leftDistributed",
        element: <FilterPayOutLeftDistributed />,
      },
      {
        path: "/payouts/leftDistributed/company/:partnerId",
        element: <CompanyFilterPayOutLeftDistributed />,
      },
      {
        path: "monthly/paid",
        element: <MonthlyPaidFilterPayOut />,
      },
      {
        path: "/payouts/monthly/paid/company/:startDate/:endDate/:partnerId",
        element: <MonthlyCompanyPaidFilterPayOut />,
      },
      {
        path: "monthly/balance",
        element: <MonthlyFilterPayOutBalance />,
      },
      {
        path: "/payouts/monthly/balance/company/:startDate/:endDate/:partnerId",
        element: <MonthlyCompanyFilterPayOutBalance />,
      },
      {
        path: "monthly/leftDistributed",
        element: <MonthlyFilterPayOutLeftDistributed />,
      },
      {
        path: "/payouts/monthly/leftDistributed/company/:startDate/:endDate/:partnerId",
        element: <MonthlyCompanyFilterPayOutLeftDistributed />,
      },
      {
        path: "monthly",
        element: <MonthlyFilterPayOut />,
      },
      {
        path: "month_payout",
        element: <MonthlyPayout />,
      },
      {
        path: "year_payout",
        element: <YearlyPayout />,
      },
      {
        path: "month_payin",
        element: <MonthlyPayIn />,
      },
      {
        path: "year_payin",
        element: <YearlyPayIn />,
      },
      {
        path: "/payouts/company/:partnerId",
        element: <CompanyFilterPayOut />,
      },
      {
        path: "/payouts/monthly/company/:startDate/:endDate/:partnerId",
        element: <MonthlyCompanyFilterPayOut />,
      },
      {
        path: "year_netPremium",
        element: <YearlyNetPremium />,
      },
      {
        path: "year_final_netPremium",
        element: <YearlyFinalNetPremium />,
      },
      {
        path: "month_netPremium",
        element: <MonthlyNetPremium />,
      },
      {
        path: "month_final_premium",
        element: <MonthlyFinalNetPremium />,
      },
    ],
  },
  {
    path: "account",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Accounts />
          </SuspenseWrapper>
        ),
      },
      {
        path: "tds-payin",
        element: (
          <SuspenseWrapper>
            <TdsPayInManage />
          </SuspenseWrapper>
        ),
      },
      {
        path: "tds-payout",
        element: (
          <SuspenseWrapper>
            <TdsPayOutManage />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/partner-debit",
        element: (
          <SuspenseWrapper>
            <PartnerDebit />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/broker-credit",
        element: (
          <SuspenseWrapper>
            <BrokerCredit />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/broker-payment-compare",
        element: (
          <SuspenseWrapper>
            <CompareBrokerPayment />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/partner-payment-compare",
        element: (
          <SuspenseWrapper>
            <ComparePartnerPayment />
          </SuspenseWrapper>
        ),
      },
      {
        path: "creditdebit",
        element: (
          <SuspenseWrapper>
            <CreditDebits />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/creditdebit/broker-payment/view",
        element: (
          <SuspenseWrapper>
            <ViewCreditDebitByBrokerCard />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/creditdebit/partner-payment/view",
        element: (
          <SuspenseWrapper>
            <ViewCreditDebitByPartnerCard />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/creditdebit/:accountId/view",
        element: (
          <SuspenseWrapper>
            <ViewAccountCreditDebitsDetails />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/creditdebit/add",
        element: (
          <SuspenseWrapper>
            <AddCreditDebits />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/creditdebit/:creditDebitId/edit",
        element: (
          <SuspenseWrapper>
            <AddCreditDebits />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddAccounts />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/:accountId/edit",
        element: (
          <SuspenseWrapper>
            <AddAccounts />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/broker/update-payment",
        element: (
          <SuspenseWrapper>
            <UpdatePayment />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/partner/update-payment",
        element: (
          <SuspenseWrapper>
            <ManagePartnerPayment />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/excel-payIn",
        element: (
          <SuspenseWrapper>
            <ExcelPayIn />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/account/excel-payOut",
        element: (
          <SuspenseWrapper>
            <ExcelPayout />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "booking",
    children: [
      {
        path: "",
        element: <BookingRequests />,
      },
      {
        path: "new",
        element: <NewBookingRequests />,
      },
      {
        path: "publish",
        element: (
          <SuspenseWrapper>
            <PublishedPolicyPage />
          </SuspenseWrapper>
        ),
      },
      {
        path: "reject",
        element: <RejectionPolicies />,
      },
      {
        path: "add",
        element: <AddBookingRequest />,
      },
      {
        path: "accepted",
        element: <AcceptedBooking />,
      },
      {
        path: "/booking/:leadId/new",
        element: <AddBookingRequest />,
      },
    ],
  },
  {
    path: "policytype",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <PolicyTypes />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddPolicyType />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/policytype/:policyTypeId/edit",
        element: (
          <SuspenseWrapper>
            <AddPolicyType />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "policy",
    children: [
      {
        path: "/policy/renewals",
        element: (
          <SuspenseWrapper>
            <GetRenewals />
          </SuspenseWrapper>
        ),
      },
      {
        path: "disputed-policies",
        element: (
          <SuspenseWrapper>
            <DisputedPolicyPage />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/policy/motor/upload",
        element: (
          <SuspenseWrapper>
            <PolicyPDF />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/policy/motor/excel/upload",
        element: (
          <SuspenseWrapper>
            <UploadPolicy />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/policy/motor/add",
        element: (
          <SuspenseWrapper>
            <AddMotorPolicy />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/policy/motor/:bookingRequestId",
        element: (
          <SuspenseWrapper>
            <EditMotorPolicy />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/policy/:policyId/view",
        element: (
          <SuspenseWrapper>
            <ViewPolicy />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/policy/:policyId",
        element: (
          <SuspenseWrapper>
            <ViewPolicyDetails />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/policy/:policyId/edit",
        element: <AddMotorPolicy />,
      },
      {
        path: "/policy/:policyId/commission",
        element: (
          <SuspenseWrapper>
            <EditCommission />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/policy/policy-dispute",
        element: (
          <SuspenseWrapper>
            <PolicyDispute />
          </SuspenseWrapper>
        ),
      },
      {
        path: "motorpolicies",
        element: (
          <SuspenseWrapper>
            <GetMotorPolicies />
          </SuspenseWrapper>
        ),
      },
      {
        path: "archive",
        element: (
          <SuspenseWrapper>
            <GetArchiveMotorPolicies />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "ranks",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Ranks />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddRank />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/ranks/:rankId/edit",
        element: (
          <SuspenseWrapper>
            <AddRank />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "team",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Teams />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddTeam />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/team/:teamId/edit",
        element: (
          <SuspenseWrapper>
            <AddTeam />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "subproducts",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <ProductSubTypes />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddProductSubType />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/subproducts/:productSubTypeId/edit",
        element: (
          <SuspenseWrapper>
            <AddProductSubType />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/subproducts/add/:productId",
        element: (
          <SuspenseWrapper>
            <AddProductSubType />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "casetype",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <CaseTypes />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddCaseType />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/casetype/:caseTypeId/edit",
        element: (
          <SuspenseWrapper>
            <AddCaseType />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "fueltype",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <FuelTypes />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddFuelType />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/fueltype/:fuelTypeId/edit",
        element: (
          <SuspenseWrapper>
            <AddFuelType />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "commission",
    children: [
      {
        path: "payout",
        element: (
          <SuspenseWrapper>
            <PayOutUploadByExcel />
          </SuspenseWrapper>
        ),
      },
      {
        path: "payin",
        element: (
          <SuspenseWrapper>
            <PayInUploadByExcel />
          </SuspenseWrapper>
        ),
      },
      {
        path: "percentage",
        element: (
          <SuspenseWrapper>
            <Percentage />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "calculate",
    children: [],
  },
  {
    path: "products",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Products />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddProduct />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/products/:productId/edit",
        element: (
          <SuspenseWrapper>
            <AddProduct />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "companies",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Companies />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddCompany />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/companies/:companyId/edit",
        element: (
          <SuspenseWrapper>
            <AddCompany />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "industry",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Industry />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddIndustry />
          </SuspenseWrapper>
        ),
      },
      {
        path: "edit",
        element: (
          <SuspenseWrapper>
            <AddIndustry />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "brokers",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Brokers />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddBroker />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/brokers/:brokerId/edit",
        element: (
          <SuspenseWrapper>
            <AddBroker />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "branch",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Branches />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddBranch />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/branch/:branchId/edit",
        element: (
          <SuspenseWrapper>
            <AddBranch />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "categories",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Categories />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddCategory />,
          </SuspenseWrapper>
        ),
      },
      {
        path: "/categories/:categoryId/edit",
        element: (
          <SuspenseWrapper>
            <AddCategory />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "models",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Models />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddModel />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/models/:modelId/edit",
        element: (
          <SuspenseWrapper>
            <AddModel />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "makes",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Makes />
          </SuspenseWrapper>
        ),
      },
      {
        path: "add",
        element: (
          <SuspenseWrapper>
            <AddMake />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/makes/:makeId/edit",
        element: (
          <SuspenseWrapper>
            <AddMake />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "accountdashboard",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <AccountDashboard />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "operationdashboard",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <OperationDashboard />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "partnerdashboard",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <PartnerDashboard />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/partnerdashboard/card",
        element: <ViewCardHistory />,
      },
      {
        path: "/partnerdashboard/withdrawal",
        element: <ManageCards />,
      },
      {
        path: "/partnerdashboard/withdrawal/:partnerId",
        element: <ManageCards />,
      },
      {
        path: "/partnerdashboard/:transactionCode/:partnerId/:startDate/:endDate/card-history",
        element: <ViewCardHistory />,
      },
    ],
  },
  {
    path: "rm",
    children: [
      {
        path: "dashboard",
        element: (
          <SuspenseWrapper>
            <RMDashboard />
          </SuspenseWrapper>
        ),
      },
      {
        path: "rm_polices",
        element: (
          <SuspenseWrapper>
            <RMGetMotorPolicies />
          </SuspenseWrapper>
        ),
      },
      {
        path: "rm_team",
        element: (
          <SuspenseWrapper>
            <RmTeams />
          </SuspenseWrapper>
        ),
      },
      {
        path: "lead",
        element: <RmLead />,
      },
      {
        path: "requested",
        element: <RmRequestedBooking />,
      },
    ],
  },
  {
    path: "bookingdashboard",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <BookingDashboard />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "403",
    children: [
      {
        path: "",
        element: <Page403 />,
      },
    ],
  },
  {
    path: "filter",
    children: [
      {
        path: "",
        element: (
          <SuspenseWrapper>
            <Filter />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    path: "notification",
    children: [
      {
        path: "",
        element: <Notification />,
      },
    ],
  },
  {
    path: "hr",
    children: [
      {
        path: "dashboard",
        element: (
          <SuspenseWrapper>
            <HrDashboard />
          </SuspenseWrapper>
        ),
      },
      {
        path: "holidays",
        element: <HolidayList />,
      },
      {
        path: "add-holiday",
        element: <AddHoliday />,
      },
      {
        path: `edit-holiday/:holidayId`,
        element: <AddHoliday />,
      },
      {
        path: `attendance`,
        element: <Attendance />,
      },
      {
        path: `attendance/:employeeId`,
        element: <EmployeeAttendance />,
      },
      {
        path: "add-attendance",
        element: <AddAttendance />,
      },
      {
        path: `/hr/edit-attendance/:id`,
        element: <AddAttendance />,
      },
    ],
  },
  {
    path: "mark-attendance",
    element: <MarkAttendance />,
  },
  {
    path: "it/dashboard",
    element: (
      <SuspenseWrapper>
        <ITDashboard />
      </SuspenseWrapper>
    ),
  },
  {
    path: "employee-task",
    element: <EmployeeTask />,
  },
  {
    path: "non-motor",
    children: [
      {
        path: "liability",
        element: <Liability />,
      },
      {
        path: "liability/add",
        element: <AddLiability />,
      },
      {
        path: "liability/edit",
        element: <AddLiability />,
      },
      {
        path: "marine",
        element: <Marine />,
      },
      {
        path: "marine/add",
        element: <AddMarine />,
      },
      {
        path: "marine/edit",
        element: <AddMarine />,
      },
      {
        path: "workmenCommunication",
        element: <WorkmenCommunication />,
      },
      {
        path: "workmenCommunication/add",
        element: <AddWorkMenCommunication />,
      },
      {
        path: "workmenCommunication/edit",
        element: <AddWorkMenCommunication />,
      },
      {
        path: "burglary",
        element: <Burglary />,
      },
      {
        path: "burglary/add",
        element: <AddBurglary />,
      },
      {
        path: "burglary/edit",
        element: <AddBurglary />,
      },
    ],
  },
];
export default routes;
