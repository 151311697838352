import { ILiability } from "../../../api/Liability/ILiability";
import { IArea } from "../../Admin/Area/IArea";
import { IIndustry } from "../../Admin/industry/AddIndustry/IIndustry";
import { IOccupancy } from "../../Admin/Occupancy/OccupancyType";

export const sumInsuredMapping = {
    "less than 5 crore": "Suksham",
    "5 to 50 crore": "Laghu",
    "above 50 crore": "standard",
};

export interface InitialValueProps {
    area?: IArea;
    industry?: IIndustry;
    industryDetails?: string;
    buildSumInsured?: string | number;
    stockSumInsured?: string | number;
    plantAndMachinery?: string | number;
    furnitureFixtureFitting?: string | number;
    totalSumInsured?: string | number;
    sumInsuredType?: string;
    sumInsuredName?: string;
    hypothisiation?: string;
    fullName?: string;
    gst?: string;
    address?: string;
    pincode?: string;
    state?: string;
    city?: string;
    email?: string;
    file?: any;
    createdBy?: string;
    phone?: string;
    occupancy:IOccupancy;
    startDate:string;
    endDate:string;
}

export interface LiabilityProps {
    propsData?: ILiability;
}
export interface IAddLiabilityProps {
    categoryId: string |undefined;
    categoryName: string |undefined;
    productTypeId: string |undefined;
    productTypeName: string |undefined;
    industryId: string;
    industry: string;
    area: string;
    areaId: string;
    industryDetails: string;
    buildSumInsured: number;
    stockSumInsured: number;
    plantAndMachinery: number;
    furnitureFixtureFitting: number;
    totalSumInsured: number;
    sumInsuredType: string;
    sumInsuredName: string;
    hypothisiation: string;
    fullName: string;
    gst: string;
    address: string;
    pincode: string;
    state: string;
    stateId: string | undefined;
    city: string;
    cityId?: string | undefined;
    email: string;
    createdBy: string;
    phone?: string;
    startDate:string;
    endDate:string;
    occupancy:string;
    occupancyId:string
    [key: string]: any;
}