import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { setIn } from "final-form";
import { FC, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import toast, { Toaster } from "react-hot-toast";
import * as yup from "yup";
import {
  ALLOWED_FILE_TYPES,
  DAY_FORMAT,
  Document,
  header,
  MAX_FILE_SIZE,
  SafeKaroUser,
} from "../../../../context/constant";
import useGetProducts from "../../../../Hooks/Product/useGetProducts";
import { ICity } from "../../../Admin/City/ICity";
import useGetState from "../../../../Hooks/State/useGetState";
import GetCityByStateIdService from "../../../../api/City/GetCityByStateId/GetCityByStateIdService";
import { IArea } from "../../../Admin/Area/IArea";
import GetAreaByCityIdServices from "../../../../api/Area/GetAreaByCityId/GetAreaByCityIdServices";

import { IIndustry } from "../../../Admin/industry/AddIndustry/IIndustry";
import useIndustry from "../../../../Hooks/Industry/useIndustry";
import {
  IAddLiabilityProps,
  InitialValueProps,
  LiabilityProps,
  sumInsuredMapping,
} from "../LiabilityType";
import AddLiabilityService from "../../../../api/Liability/AddLiability/AddLiabilityService";
import { useNavigate } from "react-router-dom";
import EditLiabilityService from "../../../../api/Liability/EditLiability/EditLiabilityService";
import generateFormData from "../../uitils/generateFormData";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { documentTypes } from "../../../Policy/IPolicyData";
import { useGetOccupancy } from "../../../../Hooks/Occupancy/useGetOccupancy";
import { IOccupancy } from "../../../Admin/Occupancy/OccupancyType";
const AddLiabilityForm: FC<LiabilityProps> = ({ propsData }) => {
  const [productData] = useGetProducts({ header, category: "Non Motor" });
  const fireProduct = productData.filter((ele) => {
    return ele.productName?.toLowerCase() === "fire";
  });
  const { data: occupancy, loading, error } = useGetOccupancy();

  const states = useGetState();
  const [selectedStateId, setSelectedStateId] = useState(propsData?.stateId);
  const [selectedCityId, setSelectedCityId] = useState(propsData?.cityId);
  const [area, setArea] = useState<IArea[]>();
  const [cities, setCities] = useState<ICity[]>([]);
  const [errors, setErrors] = useState<{ docName: string; file: string }[]>([
    { docName: "", file: "" },
  ]);
  const [documents, setDocuments] = useState<Document[]>([
    { docName: "", file: "" },
  ]);

  const [errorMessage, setErrorMessage] = useState("");

  const industryData = useIndustry();
  const navigate = useNavigate();
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let userData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const [totalSumInsured, setTotalSumInsured] = useState(
    Number(propsData?.totalSumInsured ?? 0)
  );
  const [buildSumInsured, setBuildSumInsured] = useState(
    Number(propsData?.buildSumInsured ?? 0)
  );
  const [furnitureFixtureFitting, setFurnitureFixtureFitting] = useState(
    Number(propsData?.furnitureFixtureFitting ?? 0)
  );
  const [plantAndMachinery, setPlantAndMachinery] = useState(
    Number(propsData?.plantAndMachinery ?? 0)
  );
  const [stockSumInsured, setStockSumInsured] = useState(
    Number(propsData?.stockSumInsured ?? 0)
  );
  const handleStateChange = async (newValue: any) => {
    setSelectedStateId(newValue._id);
    try {
      const res = await GetCityByStateIdService({
        header,
        stateId: newValue._id,
      });
      if (res.status === "success") {
        setCities(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const handleCityChange = async (newValue: any) => {
    setSelectedCityId(newValue._id);
    try {
      const res = await GetAreaByCityIdServices({
        header,
        id: newValue._id,
      });
      if (res.status === "success") {
        setArea(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const handleFileInputChange = (event: any, index: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileType = file.type;
      const fileSize = file.size;
      const newErrors = [...errors];

      if (!ALLOWED_FILE_TYPES.includes(fileType)) {
        // setErrorMessage("Invalid file type. Please upload an image or a PDF.");
        newErrors[index] = {
          ...newErrors[index],
          file: "Invalid file type. Please upload an image or a PDF.",
        };
        setErrors(newErrors);
      } else if (fileSize > MAX_FILE_SIZE) {
        //setErrorMessage("File size exceeds the maximum limit.");
        newErrors[index] = {
          ...newErrors[index],
          file: "File size exceeds the maximum limit",
        };
        setErrors(newErrors);
      } else {
        setErrorMessage("");
        const newDocuments = [...documents];
        newDocuments[index] = { ...newDocuments[index], file: file };
        setDocuments(newDocuments);

        // Clear the error if the file is valid
        if (newErrors[index]) {
          newErrors[index].file = "";
        }
        setErrors(newErrors);
      }
    }
  };

  const validateDocument = (document: Document, index: number) => {
    const isValidDocName = document.docName.trim() !== "";
    const isValidFile = document.file;
    validateField(index, "docName", document.docName);
    validateField(index, "file", document.file);
    return isValidDocName && isValidFile;
  };

  const validateField = (index: number, name: string, value: string) => {
    const newErrors = [...errors];
    if (name === "docName" || name === "file") {
      newErrors[index] = {
        ...newErrors[index],
        [name]: value === "" ? `${name} cannot be empty` : "",
      };
    }
    setErrors(newErrors);
  };

  const handleChangeDocumentName = (newValue: any, index: any) => {
    const updatedDocuments = documents.map((doc, i) =>
      i === index ? { ...doc, docName: newValue?.value! } : doc
    );
    setDocuments(updatedDocuments);
  };

  const handleClickAddDocument = () => {
    setDocuments([...documents, { docName: "", file: "" }]);
  };

  const handleClickDeleteDocument = (index: any) => {
    setDocuments((prevDocuments) =>
      prevDocuments.filter((_, i) => i !== index)
    );
  };
  function getSumInsuredType(amount: number) {
    if (amount < 5_000_000) {
      return {
        type: "less than 5 crore",
        name: sumInsuredMapping["less than 5 crore"],
      };
    } else if (amount >= 5_000_000 && amount <= 50_000_000) {
      return {
        type: "5 to 50 crore",
        name: sumInsuredMapping["5 to 50 crore"],
      };
    } else {
      return {
        type: "above 50 crore",
        name: sumInsuredMapping["above 50 crore"],
      };
    }
  }

  const generateInitialData = () => {
    if (propsData) {
      const {
        address,
        email,
        fullName,
        industryDetails,
        gst,
        phone,
        pincode,
        state,
        city,
        furnitureFixtureFitting,
        buildSumInsured,
        hypothisiation,
        plantAndMachinery,
        stockSumInsured,
        startDate,
        endDate,
      } = propsData;

      const area: IArea = {
        _id: propsData.areaId ?? "",
        area: propsData.area ?? "",
        cityId: propsData.cityId,
        cityName: propsData.city,
        stateId: propsData.stateId,
        stateName: propsData.state,
      };
      const industry: IIndustry = {
        _id: propsData.industryId,
        industry: propsData.industry ?? "",
      };
      const occupancy: IOccupancy = {
        _id: propsData.occupancyId || "",
        occupancy: propsData.occupancy || "",
      };

      const result: InitialValueProps = {
        address,
        email,
        fullName,
        industryDetails,
        gst,
        phone,
        pincode,
        state,
        city,
        furnitureFixtureFitting,
        buildSumInsured,
        hypothisiation,
        plantAndMachinery,
        stockSumInsured,
        industry,
        area,
        occupancy,
        startDate: dayjs(startDate).toISOString(),
        endDate: dayjs(endDate).toISOString(),
      };
      return result;
    }
  };

  const callEditLiability = async (bodyData: any) => {
    try {
      const res = await EditLiabilityService({
        id: propsData?._id!,
        liability: generateFormData(bodyData),
      });
      if (res.status) {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const callAddLiability = async (bodyData: any) => {
    try {
      const res = await AddLiabilityService({
        liability: generateFormData(bodyData),
      });
      if (res.status) {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  useEffect(() => {
    const data =
      Number(plantAndMachinery) +
      Number(buildSumInsured) +
      Number(furnitureFixtureFitting) +
      Number(stockSumInsured);
    setTotalSumInsured(data);
  }, [
    stockSumInsured,
    plantAndMachinery,
    furnitureFixtureFitting,
    buildSumInsured,
  ]);
  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});
      return errors;
    }
  };

  const validationSchema = yup.object().shape({
    city: yup
      .string()
      .required("City Name is required")
      .min(1, "City must be at least 1 character")
      .max(100, "City cannot exceed 100 characters"),
    state: yup.string().required("State is required"),
    address: yup
      .string()
      .required("Address is required")
      .max(200, "Address cannot exceed 200 characters"),
    pincode: yup
      .string()
      .required("Pincode is required")
      .matches(/^\d{6}$/, "Pincode must be a 6-digit number"),
    gst: yup
      .string()
      .required("GST is required")
      .max(200, "gst cannot exceed 200 characters"),

    fullName: yup
      .string()
      .required("Full Name is required")
      .min(2, "Full Name should be at least 2 characters"),
    email: yup
      .string()
      .required("Email is required")
      .email("Email is not valid"),
    phone: yup
      .string()
      .matches(/^\d{10}$/, "Phone Number must be exactly 10 digits long")
      .required("Phone Number is required")
      .min(1, "Phone Number must be at least 1 character")
      .max(10, "Phone Number must be at least 10 character"),
  });
  const validate = validateFormValues(validationSchema);

  const onSubmit = async (data: any) => {
    if (!propsData) {
      const formValid = documents.every((doc, index) =>
        validateDocument(doc, index)
      );
      if (!formValid) {
        return;
      }
    }
    const { _id: occupancyId, occupancy } = data.occupancy;
    const {
      address,
      email,
      fullName,
      industryDetails,
      gst,
      phone,
      pincode,
      state,
      city,
      furnitureFixtureFitting,
      buildSumInsured,
      hypothisiation,
      plantAndMachinery,
      stockSumInsured,
      endDate,
      startDate,
    } = data;
    const p = getSumInsuredType(totalSumInsured);
    const { categoryId, categoryName, _id, productName } = fireProduct[0];
    const { area, _id: areaId } = data.area;
    const { _id: industryId, industry } = data.industry;
    let payload: IAddLiabilityProps = {
      stateId: selectedStateId!,
      cityId: selectedCityId!,
      categoryId: categoryId as string,
      categoryName,
      productTypeId: _id,
      productTypeName: productName,
      fullName,
      industryDetails,
      area,
      areaId,
      gst,
      phone,
      pincode: pincode,
      state,
      city,
      furnitureFixtureFitting,
      buildSumInsured,
      hypothisiation,
      plantAndMachinery,
      stockSumInsured,
      totalSumInsured,
      sumInsuredType: p.type,
      sumInsuredName: p.name,
      industryId,
      industry,
      address,
      email,
      createdBy: userData.name,
      startDate: dayjs(startDate).format(DAY_FORMAT),
      endDate: dayjs(endDate).format(DAY_FORMAT),
      occupancy,
      occupancyId,
    };
    documents.forEach((doc: Document) => {
      if (doc.file) {
        payload[doc.docName] = doc.file;
      }
    });

    if (propsData) {
      callEditLiability(payload);
    } else {
      callAddLiability(payload);
    }
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={generateInitialData()}
        render={({ handleSubmit, submitError, submitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="state">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="state"
                          value={input.value !== undefined ? input.value : null}
                          options={states}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.state || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue.state);
                            handleStateChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select State"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              {selectedStateId && cities && (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Field name="city">
                    {({ input, meta }) => (
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="city"
                          value={input.value !== undefined ? input.value : null}
                          options={cities}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.city || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue.city);
                            handleCityChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select City"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              )}
              {selectedCityId && area && (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Field name="area">
                    {({ input, meta }) => (
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="area"
                          value={input.value !== undefined ? input.value : null}
                          options={area}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.area || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select area"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              )}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="address">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Address"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="pincode">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Pin Code"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="gst">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter GST"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="industry">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="industry"
                          value={input.value || null}
                          options={industryData}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.industry || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=" Select Industry"
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="industryDetails">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Industry Details"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="fullName">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Name"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="email">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Email"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="phone">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Phone Number"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      inputProps={{ maxLength: 10 }}
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="occupancy">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="occupancy"
                          value={input.value || null}
                          options={occupancy}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.occupancy || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=" Select Occupancy"
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="buildSumInsured">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Build Sum Insured"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      onChangeCapture={(e: any) =>
                        setBuildSumInsured(e.target.value)
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="stockSumInsured">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Stock Sum Insured"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      onChangeCapture={(e: any) =>
                        setStockSumInsured(e.target.value)
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="plantAndMachinery">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Plant And Machinery"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      onChangeCapture={(e: any) =>
                        setPlantAndMachinery(e.target.value)
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="furnitureFixtureFitting">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Furniture Fixture Fitting"
                      type="number"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      onChangeCapture={(e: any) =>
                        setFurnitureFixtureFitting(e.target.value)
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="otherSumInsured">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Any Other Item Sum Insured"
                      type="number"
                      variant="outlined"
                      size="small"
                      value={totalSumInsured}
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="totalSumInsured">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label=" Total Sum Insured"
                      type="number"
                      variant="outlined"
                      size="small"
                      value={totalSumInsured}
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="sumInsuredType">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Policy Insured Type"
                      type="text"
                      value={getSumInsuredType(totalSumInsured).type}
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="sumInsuredName">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Policy Insured Name"
                      type="text"
                      value={getSumInsuredType(totalSumInsured).name}
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="hypothisiation">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="hypothisiation"
                          value={input.value || null}
                          options={["YES", "NO"]}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.state || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=" Select Hypothecation"
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="startDate">
                  {({ input, meta }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        inputFormat="DD/MM/YYYY"
                        value={input.value || null}
                        onChange={(date) => input.onChange(date)}
                        renderInput={(params: any) => (
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...params}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="endDate">
                  {({ input, meta }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        inputFormat="DD/MM/YYYY"
                        value={input.value || null}
                        onChange={(date) => input.onChange(date)}
                        renderInput={(params: any) => (
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...params}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                </Field>
              </Grid>

              <Grid item md={12} mt={2}>
                <Button variant="outlined" onClick={handleClickAddDocument}>
                  Add More Document
                </Button>
                <Typography variant="body1" gutterBottom mr={4}>
                  {"Image should be <= 2MB."}
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                </Grid>
                {documents.map((doc, index) => (
                  <Grid item key={index} md={12} xs={12}>
                    <Grid container spacing={2} mt={1}>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Autocomplete
                          value={
                            documentTypes.find(
                              (option) => option.value === doc.docName
                            ) || null
                          }
                          //value={doc.docName}
                          onChange={(e, newValue) =>
                            handleChangeDocumentName(newValue!, index)
                          }
                          options={documentTypes}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="rounded-sm w-full"
                              size="small"
                              label="Select Document"
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                        {errors[index]?.docName && (
                          <span>{errors[index].docName}</span>
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <input
                          id={`file ${index}`}
                          type="file"
                          onChange={(e) => handleFileInputChange(e, index)}
                          style={{
                            border: "1px solid #c4c4c4",
                            padding: "5px",
                            width: "100%",
                            borderRadius: "5px",
                          }}
                        />
                        {errors[index]?.file && (
                          <span style={{ color: "red" }}>
                            {errors[index].file}
                          </span>
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        {documents.length !== 1 && (
                          <Tooltip title={"Delete Image"}>
                            <IconButton
                              color="primary"
                              aria-label={"Delete Image"}
                              component="span"
                              onClick={() => handleClickDeleteDocument(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {submitError && (
                  <div className="error text-safekaroDarkOrange">
                    {submitError}
                  </div>
                )}
                <Button variant="contained" type="submit">
                  submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};
export default AddLiabilityForm;
