import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editMarineEp as endpoint } from "../apiEndpoints";
import { IEditMarine } from "../IMarine";

const EditMarineAPI = async ({
    id, marine
}: IEditMarine) => {
    const url = endpoint(id)
    const options: FetchOptions = {
        method: "PUT",
        body: marine,
    }

    return fetchInterceptor(url, options)


};

export default EditMarineAPI;