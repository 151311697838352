import { IAddWorkmenCommunication } from "../IWorkmenCommunication";
import AddWorkmenCommunicationAPI from "./AddWorkmenCommunicationAPI";

const AddWorkmenCommunicationService = async ({ workmenCommunication }: IAddWorkmenCommunication): Promise<any> => {
    try {
        const res = await AddWorkmenCommunicationAPI({
            workmenCommunication
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default AddWorkmenCommunicationService;
