import React from "react";
import { HRmenuItems, MenuItem, SidebarProps } from "./SideBarMenusList";
import DynamicSideBar from "./DynamicSideBar";
const menuItems: MenuItem[] = HRmenuItems;
const HRSidebar: React.FC<SidebarProps> = ({
  isSidebarOpen,
  setSidebarOpen,
}) => {
  return (
    <DynamicSideBar
      isSidebarOpen={isSidebarOpen}
      setSidebarOpen={setSidebarOpen}
      menuList={menuItems}
    />
  );
};

export default HRSidebar;
