
import fetchInterceptor, { FetchOptions } from '../../../utils/fetchInterceptor ';
import { IGetBurglary  } from '../IBurglary';
import { getBurglaryEp as endpoint } from '../apiEndpoints';
const GetBurglaryAPI = async ({ header }: IGetBurglary) => {
    const url = endpoint()
    const options: FetchOptions = {
        method: "GET",
        headers: header,
    }

    return fetchInterceptor(url, options)

};

export default GetBurglaryAPI;