import React from "react";
import { AdminSideBarMenus, SidebarProps } from "./SideBarMenusList";
import DynamicSideBar from "./DynamicSideBar";

const Sidebar: React.FC<SidebarProps> = ({ isSidebarOpen, setSidebarOpen }) => {
  const menuList = AdminSideBarMenus;
  return (
    <DynamicSideBar
      isSidebarOpen={isSidebarOpen}
      setSidebarOpen={setSidebarOpen}
      menuList={menuList}
    />
  );
};
export default Sidebar;
