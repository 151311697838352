import { IAddBurglary } from "../IBurglary";
import AddBurglaryAPI from "./AddBurglaryAPI";

const AddBurglaryService = async ({  burglary }: IAddBurglary): Promise<any> => {
    try {
        const res = await AddBurglaryAPI({
            burglary
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default AddBurglaryService;
