
import fetchInterceptor, { FetchOptions } from '../../../utils/fetchInterceptor ';
import {  getNatureOfWorkEp as endpoint } from "../ApiEndPoints";
import { getReqPayload } from '../NatureOfWorkTypes';
const GetNatureOfWorkAPI = async ({ header }: getReqPayload) => {
    const url = endpoint()
    const options: FetchOptions = {
        method: "GET",
        headers: header,
    }
    return fetchInterceptor(url, options)

};

export default GetNatureOfWorkAPI;