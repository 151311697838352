import { IAddMarine } from "../IMarine";
import AddMarineAPI from "./AddMaineAPI";

const AddMarineService = async ({ marine }: IAddMarine): Promise<any> => {
    try {
        const res = await AddMarineAPI({
            marine
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default AddMarineService;
