
import fetchInterceptor, { FetchOptions } from '../../../utils/fetchInterceptor ';
import { getAllIndustryEp as endpoint } from '../apiEndPoints';
import { IGetIndustryProps } from '../IIndustry';


const GetAllIndustryAPI = async ({ header }: IGetIndustryProps) => {
    const url = endpoint()
    const options: FetchOptions = {
        method: "GET",
        headers: header,
    }

    return fetchInterceptor(url, options)

};

export default GetAllIndustryAPI;