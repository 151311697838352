import { IGetBurglary } from "../IBurglary";
import GetBurglaryAPI from "./GetBurglaryAPI";
const GetBurglaryService = async ({ header }: IGetBurglary): Promise<any> => {
    try {
        const res = await GetBurglaryAPI({ header });
        return res;
    } catch (error) {
        throw error
    }
};

export default GetBurglaryService;