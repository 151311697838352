import { IArea } from "../../Admin/Area/IArea";
import { IIndustry } from "../../Admin/industry/AddIndustry/IIndustry";
import { INatureOfWork } from "../../Admin/NatureOfWork/NatureOfWorkType";

export interface IAddWorkmenProps {
    categoryId?: string;
    categoryName?: string;
    productTypeId?: string;
    productTypeName?: string;
    industryId?: string;
    industry?: string;
    fullName?: string;
    hypothisiation?:string;
    gst?:string;
    email?: string;
    phone?: string;
    natureOfWork?: string;
    projectDuration?: string;
    natureOfWorkId?:string;
    projectTitle?: string;
    projectDescription?: string;
    NumberOfSkilledPerson?: number;
    NumberOfUnskilledPerson?: number;
    NumberOfSemiSkilledPerson?: number;
    monthlyWadgeOfSkilledPerson?: string;
    monthlyWadgeOfSemiskilledPerson?: string;
    monthlyWadgeOfUnskilledPerson?: string;
    expenseOfSkilledPersonOnProject?: string;
    expenseOfSemiskilledPersonOnProject?: string;
    expenseOfUnskilledPersonOnProject?: string;
    medicalExtension?: boolean;
    medicalExpense?: number;
    isContributorStaffCover?: boolean;
    cAddress?: string;
    cPincode?: string;
    cState?: string;
    CStateId?: string;
    cCity?: string;
    cCityId?: string;
    cAreaId?: string;
    cArea?: string;
    rAddress?: string;
    rPincode?: string;
    rState?: string;
    rStateId?: string;
    rCity?: string;
    rCityId?: string;
    rAreaId?: string;
    rArea?: string;
    bookingId?: string;
    [key: string]: any;
    startDate?: string;
    endDate?: string;
    isActive?: boolean;
    createdOn?: Date;
    createdBy?: string;
    updatedBy?: string;
    industryDetails?:string;
}


export interface IWorkmenCommunication {
    _id?:string,
    industryDetails?:string,
    categoryId?: string;
    categoryName?: string;
    productTypeId?: string;
    productTypeName?: string;
    industryId?: string;
    industry?: string;
    fullName?: string;
    email?: string;
    phone?: string;
    natureOfWork?: string;
    projectDuration?: string;
    projectTitle?: string;
    projectDescription?: string;
    NumberOfSkilledPerson?: number;
    NumberOfUnskilledPerson?: number;
    NumberOfSemiSkilledPerson?: number;
    monthlyWadgeOfSkilledPerson?: string;
    monthlyWadgeOfSemiskilledPerson?: string;
    monthlyWadgeOfUnskilledPerson?: string;
    expenseOfSkilledPersonOnProject?: string;
    expenseOfSemiskilledPersonOnProject?: string;
    expenseOfUnskilledPersonOnProject?: string;
    medicalExtension?: boolean;
    isContributorStaffCover?: boolean;
    cAddress?: string;
    hypothisiation?:string;
    gst?:string;
    cPincode?: string;
    cState?: string;
    CStateId?: string;
    cCity?: string;
    cCityId?: string;
    cAreaId?: string;
    medicalExpense:string;
    cArea?: string;
    rAddress?: string;
    rPincode?: string;
    rState?: string;
    rStateId?: string;
    rCity?: string;
    rCityId?: string;
    rAreaId?: string;
    rArea?: string;
    bookingId?: string;
    files?: Record<string, unknown>;
    startDate?: string;
    endDate?: string;
    isActive?: boolean;
    createdOn?: Date;
    createdBy?: string;
    updatedOn?: string;
    updatedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    natureOfWorkId?:string;
   
}


export interface WorkmenCommunicationProps {
    propsData?: IWorkmenCommunication;
}

export enum WorkmenProps {
    CategoryId = "categoryId",
    CategoryName = "categoryName",
    ProductTypeId = "productTypeId",
    ProductTypeName = "productTypeName",
    IndustryId = "industryId",
    Industry = "industry",
    FullName = "fullName",
    Email = "email",
    Phone = "phone",
    NatureOfWork = "natureOfWork",
    ProjectDuration = "projectDuration",
    ProjectTitle = "projectTitle",
    ProjectDescription = "projectDescription",
    NumberOfSkilledPerson = "NumberOfSkilledPerson",
    NumberOfUnskilledPerson = "NumberOfUnskilledPerson",
    NumberOfSemiskilledPerson = "NumberOfSemiSkilledPerson",
    MonthlyWageOfSkilledPerson = "monthlyWadgeOfSkilledPerson",
    MonthlyWageOfSemiSkilledPerson = "monthlyWadgeOfSemiskilledPerson",
    MonthlyWageOfUnskilledPerson = "monthlyWadgeOfUnskilledPerson",
    ExpenseOfSkilledPersonOnProject = "expenseOfSkilledPersonOnProject",
    ExpenseOfSemiSkilledPersonOnProject = "expenseOfSemiskilledPersonOnProject",
    ExpenseOfUnskilledPersonOnProject = "expenseOfUnskilledPersonOnProject",
    MedicalExtension = "medicalExtension",
    IsContributorStaffCover = "isContributorStaffCover",
    CAddress = "cAddress",
    CPincode = "cPincode",
    CState = "cState",
    CStateId = "CStateId",
    CCity = "cCity",
    CCityId = "cCityId",
    CAreaId = "cAreaId",
    CArea = "cArea",
    RAddress = "rAddress",
    RPincode = "rPincode",
    RState = "rState",
    RStateId = "rStateId",
    RCity = "rCity",
    RCityId = "rCityId",
    RAreaId = "rAreaId",
    RArea = "rArea",
    BookingId = "bookingId",
    StartDate = "startDate",
    EndDate = "endDate",
    MedicalExpense = "medicalExpense",
    ContractorStaff = "contractorStaff"

}


export interface InitialValueProps {
    cArea?: IArea;
    rArea?: IArea;
    industry?: IIndustry;
    fullName?: string;
    natureOfWork?:INatureOfWork,
    gst?: string;
    industryDetails?:string;
    email?: string;
    createdBy?: string;
    phone?: string;
    startDate: string;
    endDate: string;
    categoryId?: string;
    categoryName?: string;
    productTypeId?: string;
    productTypeName?: string;
    projectDuration?: string;
    projectTitle?: string;
    projectDescription?: string;
    NumberOfSkilledPerson?: number;
    NumberOfUnskilledPerson?: number;
    NumberOfSemiSkilledPerson?: number;
    monthlyWadgeOfSkilledPerson?: string;
    monthlyWadgeOfSemiskilledPerson?: string;
    monthlyWadgeOfUnskilledPerson?: string;
    expenseOfSkilledPersonOnProject?: string;
    expenseOfSemiskilledPersonOnProject?: string;
    expenseOfUnskilledPersonOnProject?: string;
    medicalExtension?: string;
    isContributorStaffCover?: boolean;
    cAddress?: string;
    cPincode?: string;
    cState?: string;
    CStateId?: string;
    cCity?: string;
    cCityId?: string;
    cAreaId?: string;
    rAddress?: string;
    rPincode?: string;
    rState?: string;
    rStateId?: string;
    rCity?: string;
    rCityId?: string;
    hypothisiation?:string;
    contractorStaff?: string;
    medicalExpense?:string;
}