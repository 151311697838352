import { lazy } from "react";
const Roles = lazy(() => import("../Admin/Role/Roles/Roles"));
const AddRole = lazy(() => import("../Admin/Role/AddRole/addRole"));
const Products = lazy(() => import("../Admin/Product/Products/Products"));
const AddProduct = lazy(() => import("../Admin/Product/AddProduct/addProduct"));
const AddPolicyType = lazy(() => import("../Admin/PolicyType/AddPolicyType/AddPolicyType"));
const PolicyTypes = lazy(() => import("../Admin/PolicyType/PolicyTypes/PolicyTypes"));
const CaseTypes = lazy(() => import("../Admin/CaseType/CaseTypes/CaseTypes"));
const AddCaseType = lazy(() => import("../Admin/CaseType/AddCaseType/addCaseType"));
const PayInUploadByExcel = lazy(() => import("../Admin/Percentage/PayInUploadByExcel/PayInUploadByExcel"));
const PayOutUploadByExcel = lazy(() => import("../Admin/Percentage/PayOutUploadByExcel/payOutUploadByExcel"));
const AddProductSubType = lazy(() => import("../Admin/ProductSubType/AddProductSubType/AddProductSubType"));
const ProductSubTypes = lazy(() => import("../Admin/ProductSubType/ProductSubTypes/ProductSubTypes"));
const AddCompany = lazy(() => import("../Admin/Company/AddCompany/addCompany"));
const Companies = lazy(() => import("../Admin/Company/Companies/Companies"));
const Brokers = lazy(() => import("../Admin/Broker/Brokers/Brokers"));
const AddBroker = lazy(() => import("../Admin/Broker/AddBroker/addBroker"));
const AddBranch = lazy(() => import("../Admin/Branch/AddBranch/addBranch"));
const Branches = lazy(() => import("../Admin/Branch/Branches/Branches"));
const Categories = lazy(() => import("../Admin/Category/Categories/Categories"));
const AddCategory = lazy(() => import("../Admin/Category/AddCategory/AddCategory"));
const AddFuelType = lazy(() => import("../Admin/FuelType/AddFuelType/addFuelType"));
const FuelTypes = lazy(() => import("../Admin/FuelType/FuelTypes/FuelTypes"));
const AddMake = lazy(() => import("../Admin/Make/AddMake/addMake"));
const Makes = lazy(() => import("../Admin/Make/Makes/Makes"));
const Models = lazy(() => import("../Admin/Model/Models/Models"));
const AddModel = lazy(() => import("../Admin/Model/AddModel/addModel"));
const Teams = lazy(() => import("../Admin/Team/Teams/Teams"));
const AddTeam = lazy(() => import("../Admin/Team/AddTeam/AddTeam"));
const Percentage = lazy(() => import("../Admin/Percentage/Percentage/Percentage"));
const Partners = lazy(() => import("../Admin/Team/Teams/Partners"));
const Operations = lazy(() => import("../Admin/Team/Teams/Operations"));
const RM = lazy(() => import("../Admin/Team/Teams/RM"));
const Bookings = lazy(() => import("../Admin/Team/Teams/Bookings"));
const RmTeams = lazy(() => import("../Admin/Team/Teams/RmTeam"));
const Ranks = lazy(() => import("../Admin/Rank/Ranks/Ranks"));
const AddRank = lazy(() => import("../Admin/Rank/AddRank/addRank"));
const Industry = lazy(() => import("../Admin/industry/Industry"));
const AddIndustry = lazy(() => import("../Admin/industry/AddIndustry/AddIndustry"));
const State = lazy(() => import("../Admin/State/State"));
const City = lazy(() => import("../Admin/City/City"));
const Area = lazy(() => import("../Admin/Area/Area"));
const AddState = lazy(() => import("../Admin/State/AddState/AddState"));
const AddCity = lazy(() => import("../Admin/City/AddCity/AddCity"));
const AddArea = lazy(() => import("../Admin/Area/AddArea/AddArea"));
const Task = lazy(() => import("../Admin/Task/Task"));
const AddTask = lazy(() => import("../Admin/Task/AddTask/AddTask"));
const Investigation = lazy(() => import("../Admin/Investigation/Investigation"));
const AddInvestigation = lazy(() => import("../Admin/Investigation/AddInvestigation/AddInvestigation"));
const Occupancy = lazy(() => import("../Admin/Occupancy/Occupancy"));
const AddOccupancy = lazy(() => import("../Admin/Occupancy/AddOccupancy/AddOccupancy"));
const NatureOfWork = lazy(() => import("../Admin/NatureOfWork/NatureOfWork"));
const AddNatureOfWork = lazy(() => import("../Admin/NatureOfWork/AddNatureOfWork/AddNatureOfWork"));

export {
  Roles,
  AddRole,
  Products,
  AddProduct,
  AddPolicyType,
  PolicyTypes,
  CaseTypes,
  AddCaseType,
  PayInUploadByExcel,
  PayOutUploadByExcel,
  AddProductSubType,
  ProductSubTypes,
  AddCompany,
  Companies,
  Brokers,
  AddBroker,
  AddBranch,
  Branches,
  Categories,
  AddCategory,
  AddFuelType,
  FuelTypes,
  AddMake,
  Makes,
  Models,
  AddModel,
  Teams,
  AddTeam,
  Percentage,
  Partners,
  Operations,
  RM,
  Bookings,
  RmTeams,
  Ranks,
  AddRank,
  Industry,
  AddIndustry,
  State,
  City,
  Area,
  AddState,
  AddCity,
  AddArea,
  Task,
  AddTask,
  Investigation,
  AddInvestigation,
  Occupancy,
  AddOccupancy,
  NatureOfWork,
  AddNatureOfWork,
};
