import { IGetIndustryProps } from "../IIndustry";
import GetAllIndustryAPI from "./GetAllIndustryAPI";



const GetAllIndustryService = async ({ header }: IGetIndustryProps): Promise<any> => {
    try {
        const res = await GetAllIndustryAPI({ header });
        return res;
    } catch (error) {
        throw error
    }
};

export default GetAllIndustryService;