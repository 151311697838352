import { Button, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import {
  IWorkmenCommunication,
  WorkmenProps,
} from "./WorkmenCommunicationType";
import GetWorkmenCommunicationService from "../../../api/WorkmenCommunication/GetWorkmenCommunication/GetWorkmenCommunicationService";
import { header } from "../../../context/constant";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import dayjs from "dayjs";
const WorkmenCommunication = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [workMenData, setWorkMenData] = useState<IWorkmenCommunication[]>();
  const navigate = useNavigate();
  const columns = useMemo<MRT_ColumnDef<IWorkmenCommunication>[]>(
    () => [
      { accessorKey: "industry", header: "Industry", size: 150 },
      { accessorKey: "industryDetails", header: "Details", size: 150 },
      { accessorKey: "fullName", header: "Name", size: 150 },
      { accessorKey: "email", header: "Email", size: 150 },
      { accessorKey: "phone", header: "Phone", size: 150 },
      { accessorKey: "cState", header: "Corr. State", size: 150 },
      { accessorKey: "cCity", header: "Corr. City", size: 150 },
      { accessorKey: "cArea", header: "Corr. Area", size: 150 },
      { accessorKey: "cAddress", header: "Corr. Address", size: 150 },
      { accessorKey: "cPincode", header: "Corr. Pincode", size: 150 },
      { accessorKey: "rState", header: "Risk State", size: 150 },
      { accessorKey: "rCity", header: "Risk City", size: 150 },
      { accessorKey: "rArea", header: "Risk Area", size: 150 },
      { accessorKey: "rAddress", header: "Risk Address", size: 150 },
      { accessorKey: "rPincode", header: "Risk Pincode", size: 150 },
      { accessorKey: "natureOfWork", header: "Nature of Work", size: 150 },
      {
        accessorKey: WorkmenProps.ProjectDuration,
        header: "Duration",
        size: 150,
      },
      { accessorKey: WorkmenProps.ProjectTitle, header: "Title", size: 150 },
      {
        accessorKey: WorkmenProps.ProjectDescription,
        header: "Description",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return value.substring(0, 20) + "...";
        },
      },
      {
        accessorKey: WorkmenProps.NumberOfSkilledPerson,
        header: "Skilled Count",
        size: 150,
      },
      {
        accessorKey: WorkmenProps.MonthlyWageOfSkilledPerson,
        header: "Skilled Wage",
        size: 150,
      },
      {
        accessorKey: WorkmenProps.ExpenseOfSkilledPersonOnProject,
        header: "Skilled Expense",
        size: 150,
      },
      {
        accessorKey: WorkmenProps.NumberOfSemiskilledPerson,
        header: "Semi-skilled Count",
        size: 150,
      },
      {
        accessorKey: WorkmenProps.MonthlyWageOfSemiSkilledPerson,
        header: "Semi-skilled Wage",
        size: 150,
      },
      {
        accessorKey: WorkmenProps.ExpenseOfSemiSkilledPersonOnProject,
        header: "Semi-skilled Expense",
        size: 150,
      },
      {
        accessorKey: WorkmenProps.NumberOfUnskilledPerson,
        header: "Unskilled Count",
        size: 150,
      },
      {
        accessorKey: WorkmenProps.MonthlyWageOfUnskilledPerson,
        header: "Unskilled Wage",
        size: 150,
      },
      {
        accessorKey: WorkmenProps.ExpenseOfUnskilledPersonOnProject,
        header: "Unskilled Expense",
        size: 150,
      },
      {
        accessorKey: "createdOn",
        header: "Created",
        size: 200,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return <span>{dayjs(value).format("DD MMM YYYY")}</span>;
        },
      },
      {
        accessorKey: "isActive",
        header: "Status",
        size: 100,
        Cell: ({ cell }) => {
          const value = cell.getValue<boolean>();
          return value ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          );
        },
      },
      { accessorKey: "createdBy", header: "Creator", size: 150 },
    ],

    []
  );
  const handleClickEditWC = (row: IWorkmenCommunication) => {
    navigate("edit", { state: row });
  };
  const fetchData = async () => {
    try {
      const res = await GetWorkmenCommunicationService({ header });

      if (res.success) {
        setWorkMenData(res.workmenCommunicationPolicies);
      }
    } catch (err: any) {
      const e = await err;
      toast.error(e.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          Workmen Communication
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard{"/"}
              </Link>
              <span className="text-grey-600 text-sm">
                Workmen Communication
              </span>
            </div>
            <Link to="add">
              <Button
                type="button"
                className="w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
              >
                Add Workmen Communication
              </Button>
            </Link>
          </div>
        </Typography>
        <MaterialReactTable
          state={{ pagination }}
          columns={columns}
          data={workMenData || []}
          enableRowActions
          onPaginationChange={setPagination}
          autoResetPageIndex={false}
          paginateExpandedRows={false}
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <div style={{ display: "flex", flexWrap: "nowrap" }}>
              <Tooltip title={"Edit WC"}>
                <IconButton
                  color="primary"
                  aria-label={"Edit WC"}
                  component="span"
                  onClick={() => {
                    handleClickEditWC(row.original as IWorkmenCommunication);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 text-addButton"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
              
            </div>
          )}
        />
      </Paper>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
};

export default WorkmenCommunication;
