

import { IGetTaskProps } from "../ITask";
import GetTaskByEIdAPI from "./GetTaskByEIdAPI";

const GetTaskByEIdService = async ({ header, id }: IGetTaskProps): Promise<any> => {
    try {
        const res = await GetTaskByEIdAPI({
            header: header, id
        })
        return res;
    } catch (error) {
        throw error;
    }

};

export default GetTaskByEIdService;