import { useEffect, useRef, useState } from "react"
import { header } from "../../context/constant";
import { IIndustry } from "../../components/Admin/industry/AddIndustry/IIndustry";
import GetAllIndustryService from "../../api/Industry/GetAllIndustry/GetAllIndustryService";
const useIndustry = () => {
    const [industryData, setIndustryData] = useState<IIndustry[]>([]);
    const isLoading = useRef(true);
    useEffect(() => {
        if (isLoading.current) {
            GetAllIndustryService({ header })
                .then((apiResponse) => {
                    isLoading.current = false;
                    setIndustryData(apiResponse.data!);
                })
                .catch((res) => {
                    console.error(res.status);
                });
        }
        // eslint-disable-next-line
    }, [header, isLoading]);

    return industryData;
}

export default useIndustry